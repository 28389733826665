import { defineStore } from "pinia"
import Tr from "@/translations"


export const useBlogNewsStore = defineStore("BlogNewsStore", {

    state: () => ({
        news: [],
        links: [],
        item: null,
        error: null,
        loadingData: false
    }),
    actions: {
        async fetchData(payload) {
          try {
            this.loadingData = true
            const res = await fetch(process.env.VUE_APP_ROOT_API+'blog-news?language='+Tr.currentLocale+'&paginate=true&page='+payload.page)
            const data = await res.json()
            
            if(data.error) throw Error(data.message)
            if(data.code != "200") throw Error(data.message)
    
            this.news = await data.results.news
            this.links = await data.results.links
            this.error = null
            
            if(!data.error) {
              this.loadingData = false
    
            }
          } catch (err) {
            this.loadingData = false
            this.error = err.message
            // throw Error(err.message)
          }
        }, 
        async getBySlug(payload) {
          try {
            this.loadingData = true
            const res = await fetch(process.env.VUE_APP_ROOT_API+'blog-news/'+payload.slug+'?language='+Tr.currentLocale)
            const data = await res.json()
            
            if(data.error) throw Error(data.message)
            if(data.code != "200") throw Error(data.message)
    
            // this.news = await data.results.news
            this.item = await data.results
            this.error = null
            
            if(!data.error) {
              this.loadingData = false
    
            }
          } catch (err) {
            this.loadingData = false
            this.error = err.message
            // throw Error(err.message)
          }
        }

    }
})

