	<template>
	<div id="content" class="sections"> 

		<Head>
			<!-- Primary Meta Tags -->
			<title>{{ changeMetatagHtml($t("home.metaTitle")) }}</title>
			<meta name="title" :content="changeMetatagHtml($t('home.metaTitle'))">


			<link rel="canonical" href="https://edmproduct.com/es" />
            <link rel="alternate" href="https://edmproduct.com/es" hreflang="es" />
            <link rel="alternate" href="https://edmproduct.com/en" hreflang="en" />
            <link rel="alternate" href="https://edmproduct.com/fr" hreflang="fr" />
            <link rel="alternate" href="https://edmproduct.com/pt" hreflang="pt" />
			<link rel="alternate" href="https://edmproduct.com/ca" hreflang="ca-es" />            
            <link rel="alternate" href="https://edmproduct.com/es" hreflang="x-default" />
			
			<meta name="description" :content="$t('home.metaDescription')">

			<!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://edmproduct.com/">
			<meta property="og:title" :content="changeMetatagHtml($t('home.metaTitle'))">
			<meta property="og:description" :content="$t('home.metaDescription')">
			<meta property="og:image" content="https://edmproduct.com/img/edm-logo.png">

			<!-- Twitter -->
			<meta property="twitter:card" content="summary_large_image">
			<meta property="twitter:url" content="https://edmproduct.com/">
			<meta property="twitter:title" :content="changeMetatagHtml($t('home.metaTitle'))">
			<meta property="twitter:description" :content="$t('home.metaDescription')">
			<meta property="twitter:image" content="https://edmproduct.com/img/edm-logo.png">
		</Head>



		<div class="section paddingHuge photoBackground" id="background1"><div></div></div>

		<div class="section paddingBig light verticallyCentered">
			<div>
				<div class="banner">
					<div class="header">
						<h1 class="title extra-bold mb-4 mt-md-5 display-5 ls-tighter">{{ $t('home.welcome.title1') }}
							<span class="position-relative z-index-9">{{ $t('home.welcome.title2') }}
							<!-- SVG START -->
							<span class="position-absolute top-50 start-50 translate-middle z-index-n1 d-none d-md-block mt-4">
								<svg width="390.5px" height="21.5px" viewBox="0 0 445.5 21.5">
									<path class="fill-secondary opacity-7" d="M409.9,2.6c-9.7-0.6-19.5-1-29.2-1.5c-3.2-0.2-6.4-0.2-9.7-0.3c-7-0.2-14-0.4-20.9-0.5 c-3.9-0.1-7.8-0.2-11.7-0.3c-1.1,0-2.3,0-3.4,0c-2.5,0-5.1,0-7.6,0c-11.5,0-23,0-34.5,0c-2.7,0-5.5,0.1-8.2,0.1 c-6.8,0.1-13.6,0.2-20.3,0.3c-7.7,0.1-15.3,0.1-23,0.3c-12.4,0.3-24.8,0.6-37.1,0.9c-7.2,0.2-14.3,0.3-21.5,0.6 c-12.3,0.5-24.7,1-37,1.5c-6.7,0.3-13.5,0.5-20.2,0.9C112.7,5.3,99.9,6,87.1,6.7C80.3,7.1,73.5,7.4,66.7,8 C54,9.1,41.3,10.1,28.5,11.2c-2.7,0.2-5.5,0.5-8.2,0.7c-5.5,0.5-11,1.2-16.4,1.8c-0.3,0-0.7,0.1-1,0.1c-0.7,0.2-1.2,0.5-1.7,1 C0.4,15.6,0,16.6,0,17.6c0,1,0.4,2,1.1,2.7c0.7,0.7,1.8,1.2,2.7,1.1c6.6-0.7,13.2-1.5,19.8-2.1c6.1-0.5,12.3-1,18.4-1.6 c6.7-0.6,13.4-1.1,20.1-1.7c2.7-0.2,5.4-0.5,8.1-0.7c10.4-0.6,20.9-1.1,31.3-1.7c6.5-0.4,13-0.7,19.5-1.1c2.7-0.1,5.4-0.3,8.1-0.4 c10.3-0.4,20.7-0.8,31-1.2c6.3-0.2,12.5-0.5,18.8-0.7c2.1-0.1,4.2-0.2,6.3-0.2c11.2-0.3,22.3-0.5,33.5-0.8 c6.2-0.1,12.5-0.3,18.7-0.4c2.2-0.1,4.4-0.1,6.7-0.1c11.5-0.1,23-0.2,34.6-0.4c7.2-0.1,14.4-0.1,21.6-0.1c12.2,0,24.5,0.1,36.7,0.1 c2.4,0,4.8,0.1,7.2,0.2c6.8,0.2,13.5,0.4,20.3,0.6c5.1,0.2,10.1,0.3,15.2,0.4c3.6,0.1,7.2,0.4,10.8,0.6c10.6,0.6,21.1,1.2,31.7,1.8 c2.7,0.2,5.4,0.4,8,0.6c2.9,0.2,5.8,0.4,8.6,0.7c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,2.2,0.2,3.2-0.4c0.9-0.5,1.6-1.5,1.9-2.5 c0.6-2.2-0.7-4.5-2.9-5.2c-1.9-0.5-3.9-0.7-5.9-0.9c-1.4-0.1-2.7-0.3-4.1-0.4c-2.6-0.3-5.2-0.4-7.9-0.6 C419.7,3.1,414.8,2.9,409.9,2.6z"></path>
								</svg>
							</span>
							<!-- SVG END -->
						</span></h1>
						<h3 v-html="$t('home.welcome.subtitle')" class="subTitle medium-bold text-secondary"></h3>
					</div>
					<div v-html="$t('home.welcome.text1')" class="description text-grey"></div>
					<div v-html="$t('home.welcome.text2')" class="description text-grey"></div>
				</div>
			</div>
		</div>
		<!-- #5c7a8a -->
		

		<div class="section paddingBigger light">
			<div>
				<div class="banner">
					<div class="header">
						<h2 class="medium-bold ls-tighter">{{ $t('home.season.title1') }}
							<span class="position-relative z-index-9">{{ $t('home.season.title2') }}
								<!-- SVG START -->
								<span class="position-absolute top-50 start-50 translate-middle z-index-n1 d-none d-sm-block mt-4">
									<svg width="330px" height="21.5px" viewBox="0 0 445.5 21.5">
										<path class="fill-secondary opacity-7" d="M409.9,2.6c-9.7-0.6-19.5-1-29.2-1.5c-3.2-0.2-6.4-0.2-9.7-0.3c-7-0.2-14-0.4-20.9-0.5 c-3.9-0.1-7.8-0.2-11.7-0.3c-1.1,0-2.3,0-3.4,0c-2.5,0-5.1,0-7.6,0c-11.5,0-23,0-34.5,0c-2.7,0-5.5,0.1-8.2,0.1 c-6.8,0.1-13.6,0.2-20.3,0.3c-7.7,0.1-15.3,0.1-23,0.3c-12.4,0.3-24.8,0.6-37.1,0.9c-7.2,0.2-14.3,0.3-21.5,0.6 c-12.3,0.5-24.7,1-37,1.5c-6.7,0.3-13.5,0.5-20.2,0.9C112.7,5.3,99.9,6,87.1,6.7C80.3,7.1,73.5,7.4,66.7,8 C54,9.1,41.3,10.1,28.5,11.2c-2.7,0.2-5.5,0.5-8.2,0.7c-5.5,0.5-11,1.2-16.4,1.8c-0.3,0-0.7,0.1-1,0.1c-0.7,0.2-1.2,0.5-1.7,1 C0.4,15.6,0,16.6,0,17.6c0,1,0.4,2,1.1,2.7c0.7,0.7,1.8,1.2,2.7,1.1c6.6-0.7,13.2-1.5,19.8-2.1c6.1-0.5,12.3-1,18.4-1.6 c6.7-0.6,13.4-1.1,20.1-1.7c2.7-0.2,5.4-0.5,8.1-0.7c10.4-0.6,20.9-1.1,31.3-1.7c6.5-0.4,13-0.7,19.5-1.1c2.7-0.1,5.4-0.3,8.1-0.4 c10.3-0.4,20.7-0.8,31-1.2c6.3-0.2,12.5-0.5,18.8-0.7c2.1-0.1,4.2-0.2,6.3-0.2c11.2-0.3,22.3-0.5,33.5-0.8 c6.2-0.1,12.5-0.3,18.7-0.4c2.2-0.1,4.4-0.1,6.7-0.1c11.5-0.1,23-0.2,34.6-0.4c7.2-0.1,14.4-0.1,21.6-0.1c12.2,0,24.5,0.1,36.7,0.1 c2.4,0,4.8,0.1,7.2,0.2c6.8,0.2,13.5,0.4,20.3,0.6c5.1,0.2,10.1,0.3,15.2,0.4c3.6,0.1,7.2,0.4,10.8,0.6c10.6,0.6,21.1,1.2,31.7,1.8 c2.7,0.2,5.4,0.4,8,0.6c2.9,0.2,5.8,0.4,8.6,0.7c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,2.2,0.2,3.2-0.4c0.9-0.5,1.6-1.5,1.9-2.5 c0.6-2.2-0.7-4.5-2.9-5.2c-1.9-0.5-3.9-0.7-5.9-0.9c-1.4-0.1-2.7-0.3-4.1-0.4c-2.6-0.3-5.2-0.4-7.9-0.6 C419.7,3.1,414.8,2.9,409.9,2.6z"></path>
									</svg>
								</span>
								<!-- SVG END -->
							</span>
						</h2>
					</div>
				</div>
			</div>
		</div>
		<SeasonalBanners />

		<div class="section paddingHuge light" style="padding-left: 0; padding-right: 0;"><div style="max-width:100%">

			<div class="panelButtons" style="background-color: #f4f5f4;">

				<div class="panelButton">
					<div class="texts withBorderTop">
						<h3 class="title extra-bold">{{ $t('panelButtonsTechnicalService.title') }}</h3>
						<h4 class="title medium-bold text-secondary">{{ $t('panelButtonsTechnicalService.subtitle') }}</h4>
						<p class="text text-grey">{{ $t('panelButtonsTechnicalService.descriptionParagraph1') }}</p>
						<p class="text text-grey">{{ $t('panelButtonsTechnicalService.descriptionParagraph2') }}</p>
					</div>
									
					<div class="photo" id="background3"></div>
				</div>
				<div class="panelButton">
					<div class="texts withBorderBottom">
						<h4 class="title medium-bold text-secondary" v-html="$t('panelButtonsTechnicalService.title2')"></h4>
						<p class="text text-grey">{{ $t('panelButtonsTechnicalService.descriptionParagraph3') }}</p>
						<LocalizedLink :to="$t('routes.technicalAssistance')" class="button"><div class="text">{{ $t("nav.technicalAssistance") }}</div></LocalizedLink>
					</div>
									
					<div class="photo" id="background4"></div>
				</div>
				

			</div>

		</div></div>


		<div class="section dark paddingHuge photoBackground lightPhoto narrowLeft" id="background5"><div>

			<div class="banner withHeight">
				<div class="header">
					<h2 class="title extra-bold mb-3 ls-wide ts-black">{{ $t('photoBackgroundSectionHome.title') }}</h2>
					<div v-html="$t('photoBackgroundSectionHome.subTitle')" class="subTitle ls-wide ts-black"></div>
				</div>
				<div v-html="$t('photoBackgroundSectionHome.description')" class="description ls-wide ts-black"></div>
				<div class="buttons wrap centered">
					<LocalizedLink class="button" :to="$t('routes.spareParts')">
						<div class="text">{{ $t('photoBackgroundSectionHome.buttonName1') }}</div>
					</LocalizedLink>
				</div>
			</div>

		</div></div>

		<div class="section light paddingHuge-t paddingBigger-b">
			<div>
				<div class="banner centered">
					<div class="header">
						<h2 class="medium-bold mb-4 ls-tighter">{{ $t('whereToBuyHome.title1') }}
							<span class="position-relative z-index-9">{{ $t('whereToBuyHome.title2') }}
								<!-- SVG START -->
								<span class="position-absolute top-50 start-50 translate-middle z-index-n1 d-none d-sm-block mt-3">
									<svg width="150px" height="21.5px" viewBox="0 0 445.5 21.5">
										<path class="fill-secondary opacity-7" d="M409.9,2.6c-9.7-0.6-19.5-1-29.2-1.5c-3.2-0.2-6.4-0.2-9.7-0.3c-7-0.2-14-0.4-20.9-0.5 c-3.9-0.1-7.8-0.2-11.7-0.3c-1.1,0-2.3,0-3.4,0c-2.5,0-5.1,0-7.6,0c-11.5,0-23,0-34.5,0c-2.7,0-5.5,0.1-8.2,0.1 c-6.8,0.1-13.6,0.2-20.3,0.3c-7.7,0.1-15.3,0.1-23,0.3c-12.4,0.3-24.8,0.6-37.1,0.9c-7.2,0.2-14.3,0.3-21.5,0.6 c-12.3,0.5-24.7,1-37,1.5c-6.7,0.3-13.5,0.5-20.2,0.9C112.7,5.3,99.9,6,87.1,6.7C80.3,7.1,73.5,7.4,66.7,8 C54,9.1,41.3,10.1,28.5,11.2c-2.7,0.2-5.5,0.5-8.2,0.7c-5.5,0.5-11,1.2-16.4,1.8c-0.3,0-0.7,0.1-1,0.1c-0.7,0.2-1.2,0.5-1.7,1 C0.4,15.6,0,16.6,0,17.6c0,1,0.4,2,1.1,2.7c0.7,0.7,1.8,1.2,2.7,1.1c6.6-0.7,13.2-1.5,19.8-2.1c6.1-0.5,12.3-1,18.4-1.6 c6.7-0.6,13.4-1.1,20.1-1.7c2.7-0.2,5.4-0.5,8.1-0.7c10.4-0.6,20.9-1.1,31.3-1.7c6.5-0.4,13-0.7,19.5-1.1c2.7-0.1,5.4-0.3,8.1-0.4 c10.3-0.4,20.7-0.8,31-1.2c6.3-0.2,12.5-0.5,18.8-0.7c2.1-0.1,4.2-0.2,6.3-0.2c11.2-0.3,22.3-0.5,33.5-0.8 c6.2-0.1,12.5-0.3,18.7-0.4c2.2-0.1,4.4-0.1,6.7-0.1c11.5-0.1,23-0.2,34.6-0.4c7.2-0.1,14.4-0.1,21.6-0.1c12.2,0,24.5,0.1,36.7,0.1 c2.4,0,4.8,0.1,7.2,0.2c6.8,0.2,13.5,0.4,20.3,0.6c5.1,0.2,10.1,0.3,15.2,0.4c3.6,0.1,7.2,0.4,10.8,0.6c10.6,0.6,21.1,1.2,31.7,1.8 c2.7,0.2,5.4,0.4,8,0.6c2.9,0.2,5.8,0.4,8.6,0.7c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,2.2,0.2,3.2-0.4c0.9-0.5,1.6-1.5,1.9-2.5 c0.6-2.2-0.7-4.5-2.9-5.2c-1.9-0.5-3.9-0.7-5.9-0.9c-1.4-0.1-2.7-0.3-4.1-0.4c-2.6-0.3-5.2-0.4-7.9-0.6 C419.7,3.1,414.8,2.9,409.9,2.6z"></path>
									</svg>
								</span>
								<!-- SVG END -->
							</span>
						</h2>
						<p class="text-grey">{{ $t('whereToBuyHome.descriptionParagraph1') }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section light" style="padding-left: 0px; padding-right: 0px;"><div style="width: 100%; overflow: hidden; height: 400px; max-width: 100%;">
			<iframe src="https://www.google.com/maps/d/embed?mid=1IpqNiVINJviqDfOslFCBOg7BivpRPrT0&ehbc=2E312F&ll=40.217462377389126%2C-5.2043832406249955&z=4" width="100%" height="800" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" style="border:0; margin-top: -150px;"></iframe>
		</div></div>

	</div>
	</template>

	<script>

	import { Head } from '@vueuse/head'
	import SeasonalBanners from "@/components/SeasonalBanners.vue"
	import LocalizedLink from "@/components/LocalizedLink.vue"





	export default {
	name: "HomeView",
	components: { Head, SeasonalBanners, LocalizedLink },

	setup() {
		
		/**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
			*/
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}
		return { changeMetatagHtml }
	}



	}
	</script>
	<style>

	
	h1 {
		margin-top: 0;
		margin-bottom: 0.5rem;
		line-height: 1.25;
	}
	@media (min-width: 1200px) {
		.display-5 {
			font-size: 3.5rem;
		}
	}
	#background1 {
		background-image: url('@/assets/img/photos/07704_43.webp');
	}
	@media (min-width:992px) {
		#background1 {
			height: 400px;
			background-size: 100%;
		}
	}
	#background3 {
		background-image: url('@/assets/img/photos/4.webp');
	}
	#background4 {
		background-image: url('@/assets/img/photos/3.webp');
	}
	#background5 {
		background-image: url('@/assets/img/photos/1.webp');
	}
	</style>



