    <template>
        <div class="section light"><div>

            <div class="panelButtons" v-if="banners.length">

                <div class="panelButton" v-for="banner in banners" :key="banner.order">
                    <div class="texts">
                        <h4 class="title" style="color: var(--colorSecondary);">{{banner.name[Tr.currentLocale] ? banner.name[Tr.currentLocale] : banner.name["es"]}}</h4>
                        <p class="text" style="color: #575656;">{{banner.description[Tr.currentLocale] ? banner.description[Tr.currentLocale] : banner.description["es"]}}</p>
                        <router-link :to="{ name: 'ProductsListView', params: { slug: banner.url[Tr.currentLocale] ? banner.url[Tr.currentLocale]['slug'] : banner.url['es']['slug'], type: banner.url[Tr.currentLocale] ? banner.url[Tr.currentLocale]['type'] : banner.url['es']['type'], code: banner.url[Tr.currentLocale] ? banner.url[Tr.currentLocale]['code'] : banner.url['es']['code'] }}" class="button">{{banner.button_name[Tr.currentLocale] ? banner.button_name[Tr.currentLocale] : banner.button_name["es"]}}</router-link>
                    </div>
                                    
                    <div class="photo" :style="getSeasonalImage(banner.image)">
                    </div>
                </div>

                

            </div>

        </div></div>
    </template>

    <script>
        import { useSeasonalBannersStore } from '../stores/SeasonalBannersStore'
        import { computed, onMounted } from 'vue'
        import GENERIC_VARIABLES from "../../config";
        import Tr from "@/translations"

        export default {
            name: 'SeasonalBanners',
                setup() {

                    const loadingData = computed(() => store.loadingData)
         
                    const store = useSeasonalBannersStore()

                
                    const banners = computed(() => store.banners)
                    const error = computed(() => store.error)

                    const apiUrl = process.env.VUE_APP_API_URL_IMAGES+'/storage/seasonal-banners/'

                    onMounted(async() => {
                        store.fetchData()
                    })

                    const getSeasonalImage = (image) => {
                        return "background-image: url('"+apiUrl+image+"')"
                    }

                    return { apiUrl, banners, error, loadingData, GENERIC_VARIABLES, Tr, getSeasonalImage}
                }
        }
    </script>