import { createRouter, createWebHistory, RouterView } from 'vue-router'
import Tr from "@/translations"
import i18n from "@/i18n"

import HomeView from '../views/HomeView.vue'
import ArticleDetailsView from '../views/sections/ArticleDetailsView.vue'
import NotFoundView from '../views/NotFoundView.vue'
// import NotFoundView301 from '../views/NotFoundView301.vue'
import AllArticles from '../views/sections/AllArticles.vue'
import Searcher from '../views/sections/SearcherView.vue'
import BestSellers from '../views/sections/BestSellers.vue'
import NoveltiesView from '../views/sections/NoveltiesView.vue'

import ProductsListView from '../views/sections/ProductsListView'
import CategoriesListView from '../views/sections/CategoriesListView'

import BlogListView from '../views/sections/BlogListView'
import BlogView from '../views/sections/BlogView'

import DataProtection from '../views/sections/DataProtection'

import TechnicalAssistance from '../views/sections/TechnicalAssistance'
import SpareParts from '../views/sections/SpareParts'
import AboutUs from '../views/sections/AboutUs'





const routes = [
    {
      path: "/",
      redirect: i18n.global.locale.value
    },
    {
      path: "/:locale",
      name: 'home',
      component: RouterView,
      beforeEnter: Tr.routeMiddleware,
      children: [
        {
          path: '',
          name: 'inicio',
          component: HomeView
        },
        { 
          path: 'quienes-somos',
          name: 'AboutUs',
          component: AboutUs,
          alias: ['qui-som','who-we-are', 'a-propos-de-nous', 'quem-somos'],
          meta: {toTop: true, smoothScroll: true}
        },
        { 
          path: 'asistencia-tecnica',
          name: 'TechnicalAssistance',
          component: TechnicalAssistance,
          alias: ['assistencia-tecnica','technical-assistance', 'assistance-technique', 'assistencia-tecnica'],
          meta: {toTop: true, smoothScroll: true}
        },
        { 
          path: 'recambios',
          name: 'SpareParts',
          component: SpareParts,
          alias: ['recanvis','spare-parts', 'pieces-de-rechange', 'sobresselentes'],
          meta: {toTop: true, smoothScroll: true}
        },
        { 
          path: 'los-mas-vendidos',
          name: 'BestSellers',
          component: BestSellers,
          alias: ['els-mes-venuts','best-sellers', 'top-ventes', 'os-mais-vendidos'],
          meta: {toTop: true, smoothScroll: true}
        },
        { 
          path: 'novedades',
          name: 'NoveltiesView',
          component: NoveltiesView,
          alias: ['novetats','novelties', 'actualites', 'novidades'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'catalogo-de-productos',
          name: 'AllArticles',
          component: AllArticles,
          alias: ['cataleg-de-productes','products-catalogue', 'catalogue-de-produits', 'catalogo-de-produtos'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'blog/:slug',
          name: 'BlogView',
          component: BlogView,
          props: true,
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'blog',
          name: 'BlogListView',
          component: BlogListView,
          meta: {toTop: true, smoothScroll: true}
        },

        {
          path: ':slug/s:code',
          name: 'CategoriesListView',
          component: CategoriesListView,
          props: true,
          meta: {toTop: true, smoothScroll: true}
        },
        {
          // path: ':supercategory/:category/:slug/:code',
          path: ':slug/p:code',
          name: 'ArticleDetailsView',
          component: ArticleDetailsView,
          props: true,
          meta: {toTop: true, smoothScroll: true}
        },        
        {
          path: ':slug/:type:code',
          name: 'ProductsListView',
          component: ProductsListView,
          props: true,
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'proteccion-de-datos',
          name: 'DataProtection',
          component: DataProtection,
          alias: ['proteccio-de-dades','data-protection', 'protection-des-donnees', 'protecao-de-dados'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'search/:query',
          name: 'Searcher',
          component: Searcher,
          props: true,
          meta: {toTop: true, smoothScroll: true}
        }
        // ,
        // { 
        //   path: '/301', 
        //   name: 'Redirect', 
        //   redirect: {
        //     await router.push('/my-profile'),
        //     redirectParams: { 
        //       responseCode: 301 
        //     }
        //   }
        // }     
      ]
    }
    
  
  ,
  { path: '/404', name: 'NotFound', component: NotFoundView },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundView }
]





const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    const scroll = {}
    if(to.meta.toTop) scroll.top = 0
    if(to.meta.smoothScroll) scroll.behavior = 'smooth'
    return scroll
  }
})

router.beforeEach((to, from, next) => {

  if(!to.params.locale) {
    to.params.locale = i18n.global.locale.value
  }
  if (to.params.locale === from.params.locale) {
    next()
    return
  }

  const { locale } = to.params
  
  Tr.switchLanguage(locale)
  next()
})

export default router
