<template>



    <div id="footer" class="sections">
		<div class="section paddingBigger light footer"><div>
			<div class="container mt-4 prefooter">
				<div class="row">
					<div class="col-12 col-md-6 logoFooter"></div>
					<div class="col-12 col-md-6 social"><div>
						{{ $t("footer.followUs") }}<br>
						<a href="https://www.facebook.com/edmproduct" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
						<a href="https://www.instagram.com/edm_product/" target="_blank"><i class="fa-brands fa-instagram"></i></a>
						<a href="https://www.youtube.com/user/EDMGrupo" target="_blank"><i class="fa-brands fa-youtube"></i></a>
						<a href="https://www.tiktok.com/@edm_product" target="_blank"><i class="fa-brands fa-tiktok"></i></a>
					</div></div>
				</div>
			</div>
		</div></div>

		<div class="section paddingBigger linkGroups dark noPaddingBottom"><div>

			<div class="linkGroup grow" v-if="menus.length">
				<div class="title medium-bold">{{ $t("footer.whatAreYouLlokingFor") }}</div>
				<div class="links">
					<LocalizedLink v-for="menu in getFirstPartArray(menus)" :key="menu.code" :to="{ name: 'CategoriesListView', params: { slug: menu.slug[Tr.currentLocale] ? menu.slug[Tr.currentLocale]['name'] : menu.slug['es']['name'], type: menu.slug[Tr.currentLocale] ? menu.slug[Tr.currentLocale]['type'] : menu.slug['es']['type'], code: menu.slug[Tr.currentLocale] ? menu.slug[Tr.currentLocale]['code'] : menu.slug['es']['code'] }}"><div class="text">{{menu.name[Tr.currentLocale] ? capitalize(menu.name[Tr.currentLocale]) : capitalize(menu.name["es"])}}</div></LocalizedLink>
				</div>
				
				<div class="links">
					<LocalizedLink v-for="menu in getSecondPartArray(menus)" :key="menu.code" :to="{ name: 'CategoriesListView', params: { slug: menu.slug[Tr.currentLocale] ? menu.slug[Tr.currentLocale]['name'] : menu.slug['es']['name'], type: menu.slug[Tr.currentLocale] ? menu.slug[Tr.currentLocale]['type'] : menu.slug['es']['type'], code: menu.slug[Tr.currentLocale] ? menu.slug[Tr.currentLocale]['code'] : menu.slug['es']['code'] }}"><div class="text">{{menu.name[Tr.currentLocale] ? capitalize(menu.name[Tr.currentLocale]) : capitalize(menu.name["es"]) }}</div></LocalizedLink>
				</div>
			</div>

			<div class="linkGroup grow" v-if="menus.length">
				<div class="title medium-bold">{{ $t("nav.products") }}</div>
				<div class="links">
					<LocalizedLink :to="$t('routes.allArticles')"><div class="text">{{ capitalize($t("nav.allArticles")) }}</div></LocalizedLink>
					<LocalizedLink :to="$t('routes.bestSellers')"><div class="text">{{ capitalize($t("nav.bestSellers")) }}</div></LocalizedLink>
					<LocalizedLink :to="$t('routes.novelties')"><div class="text">{{ $t("nav.novelties") }}</div></LocalizedLink>
				</div>
			</div>

			<div class="linkGroup grow">
				<div class="title medium-bold">{{ $t("nav.others") }}</div>
				<div class="links">
					<LocalizedLink :to="$t('routes.blog')"><div class="text">{{ $t("nav.blog") }}</div></LocalizedLink>
					<LocalizedLink :to="$t('routes.technicalAssistance')"><div class="text">{{ $t("nav.technicalAssistance") }}</div></LocalizedLink>
					<LocalizedLink :to="$t('routes.aboutUs')"><div class="text">{{ $t("nav.aboutUs") }}</div></LocalizedLink>
				</div>
			</div>

		</div></div>


		<div class="section disclaimers paddingBigger dark"><div>
			<div>&copy; EDM Product {{ new Date().getFullYear() }}</div>
			<div>{{ $t('footer.allRightsReserved') }}</div>
			<LocalizedLink class="option" :to="$t('routes.protectionData')"><div class="text">{{ $t("nav.protectionData") }}</div></LocalizedLink>
		</div></div>

	</div>      
</template>

<script>
import { useMenuStore } from '../stores/MenuStore'
import { computed, onMounted, } from 'vue'
import GENERIC_VARIABLES from "../../config";
import Tr from "@/translations"


import LocalizedLink from "./LocalizedLink.vue"

export default {
  name: 'FooterComponent',
  components: { LocalizedLink },
  setup() {
	const store = useMenuStore()
	const menus = computed(() => store.menus)
	onMounted(async() => {
		store.getSuperCategoriesMenuData()
	})

	function getFirstPartArray(menus) {
		const midIndex = Math.ceil(menus.length / 2)
		const firstPart = menus.slice(0, midIndex)
		return firstPart
	}
	function getSecondPartArray(menus) {
		const midIndex = Math.ceil(menus.length / 2)
		const secondPart = menus.slice(midIndex, menus.length)
		return secondPart
	}

	function capitalize(word) {
		return word[0].toUpperCase() + word.slice(1).toLowerCase();
	}
	
	return { menus, GENERIC_VARIABLES, Tr, getFirstPartArray, getSecondPartArray, capitalize }

  }
}

</script>