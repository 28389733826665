<template>
    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ changeMetatagHtml($t("blog.metaTitle")) }}</title>

            <link rel="canonical" href="https://edmproduct.com/es/blog" />
            <link rel="alternate" href="https://edmproduct.com/es/blog" hreflang="es" />
            <link rel="alternate" href="https://edmproduct.com/en/blog" hreflang="en" />
            <link rel="alternate" href="https://edmproduct.com/fr/blog" hreflang="fr" />
            <link rel="alternate" href="https://edmproduct.com/pt/blog" hreflang="pt" />
			<link rel="alternate" href="https://edmproduct.com/ca/blog" hreflang="ca-es" />            
            <link rel="alternate" href="https://edmproduct.com/es/blog" hreflang="x-default" />

            <meta name="title" :content="changeMetatagHtml($t('blog.metaTitle'))">
            
            <meta name="description" :content="$t('blog.metaDescription')">

            <!-- Open Graph / Facebook -->
            <meta property="og:type" content="website">
            <meta property="og:url" content="https://edmproduct.com/">
            <meta property="og:title" :content="changeMetatagHtml($t('blog.metaTitle'))">
            <meta property="og:description" :content="$t('blog.metaDescription')">
            <meta property="og:image" content="https://edmproduct.com/img/edm-logo.png">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://edmproduct.com/">
            <meta property="twitter:title" :content="changeMetatagHtml($t('blog.metaTitle'))">
            <meta property="twitter:description" :content="$t('blog.metaDescription')">
            <meta property="twitter:image" content="https://edmproduct.com/img/edm-logo.png">
        </Head>
        <div class="section padding"><div>

            <a class="breadcrumb mb-0 px-0" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
            </a>

        </div></div>

        
        <div class="section light"><div>
            <div class="section paddingHuge"><div>

                <div class="banner centered">
                    <div class="header">            
                        <h1 class="title extra-bold ls-tighter" v-if="!loadingData">
                            {{ $t('nav.blog') }}
                        </h1>
                    </div>
                </div>

            </div></div>
            

            

            <div class="banner centered" v-if="error">
                <p v-html="$t(error)"></p>
            </div>
            <div v-else>
                <div class="row mt-4 mb-4" v-if="!loadingData">
                    <div class="col-md-6" v-for="blogNew in news" :key="blogNew.order">
                        <a :href="'blog/'+blogNew.slug" class="lightBackground new">
                            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                <div class="col p-4 d-flex flex-column position-static">
                                    <h2 class="mb-0 h5">{{ blogNew.name[Tr.currentLocale] ? blogNew.name[Tr.currentLocale] : blogNew.name["es"] }}</h2>
                                    <div class="mt-1 mb-1"><small><i>{{ blogNew.createdAt }}</i></small></div>
                                    <p class="card-text mb-auto" v-html="blogNew.description[Tr.currentLocale] ? truncate(blogNew.description[Tr.currentLocale], 100, '...') : truncate(blogNew.description['es'], 100, '...')"></p>
                                </div>
                                <div class="col-auto d-none d-lg-block blogImg" v-if="blogNew.image" :style="getThumbnailImage(blogNew.image)">
                                </div>
                            </div>
                        </a>
                    </div>
                </div>                
                <div v-else style="display: flex; justify-content: center;">
                    <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                    </div>
                </div>
                <div class="banner centered">
                    <v-pagination class="mb-4" v-if="Object.keys(links).length !== 0"
                        v-model="page"
                        :pages="links[0].lastPage"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="updateHandler"
                    />
                </div>
                
            </div>
        </div></div>

        

    </div>
    </template>


<script>
    import { useBlogNewsStore } from '../../stores/BlogNewsStore'
    import { computed, onMounted, ref } from 'vue'
    import VPagination from "@hennge/vue3-pagination";
    import "@hennge/vue3-pagination/dist/vue3-pagination.css"

    import GENERIC_VARIABLES from "../../../config";
    import { Head } from '@vueuse/head'
    import Tr from "@/translations"



// Composition API and external JS
export default {
    name: "BlogListView",
    components: { Head, VPagination },
    setup() {
        const store = useBlogNewsStore()
        const apiUrl = process.env.VUE_APP_API_URL_IMAGES+'/storage/news/'      

        const loadingData = computed(() => store.loadingData)
        const links = computed(() => store.links)
        const news = computed(() => store.news)
        const error = computed(() => store.error)
        const page = ref(1)
        let payload

        payload = {
            page: page.value
        }

        onMounted(async() => {
			store.fetchData(payload)
        })


        /**
        * This is a function to remove the special character | from javascript
        * More information: https://stackoverflow.com/questions/5796718/html-entity-decode
        */
        const changeMetatagHtml = (html) => {
          let txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;

        }

        const truncate = (text, length, suffix) => {
            if (text.length > length) {
              return text.replace('<p>', '').replace('</p>', '').substring(0, length) + suffix
            } else {
              return text.replace('<p>', '').replace('</p>', '')
            }
        }

        const getThumbnailImage = (thumbail) => {
			return "background-image: url('"+apiUrl+thumbail+"')"
        }

        const updateHandler = () => {
            const payload = {
                page: page.value
            };
            store.fetchData(payload)
        }

        return { apiUrl, news, links, error, loadingData, page, GENERIC_VARIABLES, Tr, changeMetatagHtml, truncate, getThumbnailImage, updateHandler }

    }

}
</script>
