<template>
	<div>
		<div class="superNav border-bottom py-2 bg-light d-block d-lg-none d-xl-none">
			<div class="container">
				<div class="row align-middle">
					<div class="col">
						<LocaleSwitcher />
					</div>
					<div class="col me-3 my-auto text-end">
						<LocalizedLink :to="$t('routes.technicalAssistance')"><div class="text">{{ $t("nav.technicalAssistance") }}</div></LocalizedLink>
					</div>
					
				</div>
			</div>
		</div>
		<nav class="navbar navbar-expand-lg bg-white sticky-top navbar-light p-3 shadow-sm d-block d-lg-none d-xl-none">
			<div class="container">
				<LocalizedLink to="/" class="navbar-brand">
					<img src="@/assets/img/edm-logo-b.png" height="60" alt="image">
				</LocalizedLink>
				<button v-if="menus.length" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" class="btn btn-navbar navbar-toggler"><i aria-label="icon" role="img" name="hamburger-menu" width="18" height="18" class="icon menu"></i></button>		
				<div class="my-3 w-100">
					<div class="input-group">          
						<input
							type="text"
							class="form-control rounded"
							:placeholder="$t('generalMessages.searchingArticle')"
							:aria-label="$t('generalMessages.searchingArticle')"
							aria-describedby="search-addon"
							v-model="searchQuery"
							@keyup.enter="searchArticles"
						/>
						<span class="input-group-text border-0" id="search-addon" @click="searchArticles" style="background-color:  var(--colorSecondary);">
							<i class="icon search" style="color: var(--colorWhite);"></i>
						</span>
					</div>
				</div>
			</div>
		</nav>

		<nav class="navbar navbar-expand-lg navbar-light bg-light d-none d-lg-block d-xl-block">
			<!-- Container wrapper -->
			<div class="container">
				<!-- Navbar brand -->
				<LocalizedLink to="/" class="navbar-brand">
					<img src="@/assets/img/edm-logo.png" height="60" alt="EDM Product" class="d-none d-sm-block">
				</LocalizedLink>

				<!-- Toggle button -->
				<button v-if="menus.length" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" class="btn btn-navbar navbar-toggler"><i aria-label="icon" role="img" name="hamburger-menu" width="18" height="18" class="icon menu"></i><span class="text-btn-navBar d-none d-sm-block">{{ $t("nav.products") }}</span></button>

				<!-- Collapsible wrapper -->
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<!-- Left links -->
					<ul class="navbar-nav me-3">
						<li class="nav-item">
							<button v-if="menus.length" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" class="btn btn-navbar"><i aria-label="icon" role="img" name="hamburger-menu" width="18" height="18" class=" icon menu"></i><span class="text-btn-navBar">{{ $t("nav.products") }}</span></button>
						</li>
					</ul>
					<!-- Left links -->

					<div class="d-flex align-items-center w-100 form-search">
						<div class="input-group">          
							<input
							type="text"
							class="form-control rounded"
							:placeholder="$t('generalMessages.searchingArticle')"
							:aria-label="$t('generalMessages.searchingArticle')"
							aria-describedby="search-addon"
							v-model="searchQuery"
							@keyup.enter="searchArticles"
						/>
						<span class="input-group-text border-0" id="search-addon" @click="searchArticles" style="background-color:  var(--colorSecondary);">
							<i class="icon search" style="color: var(--colorWhite);"></i>
						</span>
						</div>
						<!-- <a href="#!" class="text-white"><i class="fas fa-search ps-3"></i></a> -->
					</div>

					<ul class="navbar-nav ms-3">
						<li class="nav-item me-3">
							<LocalizedLink :to="$t('routes.technicalAssistance')" class="btn btn-navbar"><i class="icon certificate"></i><div class="text-btn-navBar">{{ $t("nav.technicalAssistanceShort") }}</div></LocalizedLink>
							<!-- <button class="btn btn-navbar"><i class="icon certificate"></i><span class="text-btn-navBar">Asisténcia</span></button>							 -->
						</li>
						<li class="nav-item">
							<LocaleSwitcher />
						</li>
					</ul>
				</div>
				<!-- Collapsible wrapper -->
			</div>
			<!-- Container wrapper -->
		</nav>
		<!-- Navbar -->
		<div class="offcanvas offcanvas-start" ref="offCanvasRef" v-if="menus.length" tabindex="-1" id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel">
			<div class="offcanvas-header">
				<LocalizedLink id="offcanvasWithBackdropLabel" to="/">
					<img src="@/assets/img/edm-logo-b.png" height="50" alt="image">
				</LocalizedLink>
				<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body">
				<div class="menu"><div>
					<div v-for="menu in menus" :key="menu.code">
						<router-link :to="{ name: 'CategoriesListView', params: { slug: menu.slug[Tr.currentLocale] ? menu.slug[Tr.currentLocale]['name'] : menu.slug['es']['name'], type: menu.slug[Tr.currentLocale] ? menu.slug[Tr.currentLocale]['type'] : menu.slug['es']['type'], code: menu.slug[Tr.currentLocale] ? menu.slug[Tr.currentLocale]['code'] : menu.slug['es']['code'] }}" class="nav-link" :class="[{ active: showByIndex === menu.code }]" @click="closeNavBar()">
							<div class="flexbox-container">
								<div :class="'icon ' + menu.slug['es']['name']"></div>
								<div class="name" @mouseover="openSubmenu(menu.code)">{{menu.name[Tr.currentLocale] ? menu.name[Tr.currentLocale] : menu.name["es"]}}</div>
								<div class="icon chevron-right"></div>
							</div>
						</router-link>
						<div v-show="showByIndex === menu.code" class="categoriesMenu">
							<div v-for="children in menu.children" :key="children.code">
								<router-link :to="{ name: 'ProductsListView', params: { slug: children.slug[Tr.currentLocale] ? children.slug[Tr.currentLocale]['name'] : children.slug['es']['name'], type: children.slug[Tr.currentLocale] ? children.slug[Tr.currentLocale]['type'] : children.slug['es']['type'], code: children.slug[Tr.currentLocale] ? children.slug[Tr.currentLocale]['code'] : children.slug['es']['code'] }}" class="nav-link" @click.prevent="closeNavBar()">
								<div class="flexbox-container">
									<div class="name">{{children.name[Tr.currentLocale] ? children.name[Tr.currentLocale] : children.name["es"]}}</div>
									<div class="icon go"></div>
								</div>
							</router-link>
							</div>
						</div>
					</div>
				</div></div>
			</div>
		</div>
    
		<nav v-if="links.length" class="navbar navbar-expand-sm justify-content-center navbar-dark bg-dark navbar-parent">
			<div class="container">
				<button class="navbar-toggler notShadowFocus dropdown-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#navbarFeaturedLinks" aria-controls="navbarFeaturedLinks" aria-expanded="false" aria-button="Toggle navigation">
					{{ $t('nav.featuredLinks') }}					
				</button>
				<ul class="collapse navbar-collapse navbar-nav" id="navbarFeaturedLinks">
					<li class="d-none d-lg-block extra-bold nav-item text-uppercase">{{ $t('nav.featuredLinks') }}: </li>
					<li class="nav-item" v-for="link in links" :key="link.order">
						<router-link  @click="collapseFeaturedLinks" :to="{ name: 'ProductsListView', params: { slug: link.url[Tr.currentLocale] ? link.url[Tr.currentLocale]['slug'] : link.url['es']['slug'], type: link.url[Tr.currentLocale] ? link.url[Tr.currentLocale]['type'] : link.url['es']['type'], code: link.url[Tr.currentLocale] ? link.url[Tr.currentLocale]['code'] : link.url['es']['code'] }}" class="nav-link">{{link.name[Tr.currentLocale] ? link.name[Tr.currentLocale] : link.name["es"]}}</router-link>
					</li>
					
				</ul>
			</div>
		</nav>
	</div>
</template>

<script>
/* eslint-disable */
import { useMenuStore } from '../stores/MenuStore'
import { computed, onMounted, ref } from 'vue'
import bootstrap from "bootstrap/dist/js/bootstrap" 

import LocaleSwitcher from "./LocaleSwitcher.vue"
import LocaleSwitcherMobile from "./LocaleSwitcherMobile.vue"
import LocalizedLink from "./LocalizedLink.vue"

import GENERIC_VARIABLES from "../../config";
import Tr from "@/translations"
import { useRouter, useRoute } from "vue-router"

export default {
  name: 'HeaderComponent',
  components: { LocaleSwitcher, LocaleSwitcherMobile, LocalizedLink },
  setup() {
		
	

		const searchQuery = ref("");

		const route = useRoute()
		const router = useRouter()
        const store = useMenuStore()
		const links = computed(() => store.links)
		const menus = computed(() => store.menus)
		const showByIndex = ref(null)
		const offCanvasRef = ref(null);

		const isMobile = () => { 
			return screen.width <= 760
		}
		
		const closeNavBar = () => {
			showByIndex.value = null
			bootstrap.Offcanvas.getInstance(offCanvasRef.value).hide()

		}

		const collapseFeaturedLinks = () => {
			if(isMobile()) {
				const menuToggle = document.getElementById('navbarFeaturedLinks')
				bootstrap.Collapse.getInstance(menuToggle).toggle()
			}			
		}

		const openSubmenu = (code) => {
			showByIndex.value = code
		}

		const subIsActive = (input) => {
			const paths = Array.isArray(input) ? input : [input]
			return paths.some(path => {
				return route.path.indexOf(path) === 0 // current path starts with this path string
			})
		}

		const searchArticles = () => {
			router.push({
                name: 'Searcher',
				params: { query: searchQuery.value }
            })

		}

		onMounted(async() => {
			store.getSuperCategoriesMenuData()
            store.getFeaturedLinksData()
        })

        return { searchQuery, links, menus, offCanvasRef, searchArticles, subIsActive, Tr, closeNavBar, collapseFeaturedLinks, openSubmenu, GENERIC_VARIABLES, showByIndex }
    }
}
</script>
