<template>
    <div>
        <HeaderComponent/>
        <router-view :key="$route.path"/>
        <FooterComponent/>
        <CookieconsentComponent/>
        <MatomoComponent/>
    </div>
</template>
<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import CookieconsentComponent from './components/cookieConsent/CookieconsentComponent.vue'
import MatomoComponent from './components/matomo/MatomoComponent.vue'
// import BreadcrumbComponent from "./components/BreadcrumbComponent.vue";
// import { useHead } from '@vueuse/head'
// import { Head } from '@vueuse/head'


export default {
    name: 'App',
    components: {
        HeaderComponent,
        FooterComponent,
        CookieconsentComponent,
        MatomoComponent,
        // Head
    },
    setup () {
    }
}

</script>
