<template>
    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ titleProductsList }} | Edmproduct</title>

            <link rel="canonical" :href="'https://edmproduct.com/'+allUrlsProductsList['es']" />
            <link rel="alternate" :href="'https://edmproduct.com/'+allUrlsProductsList['es']" hreflang="es" />
            <link rel="alternate" :href="'https://edmproduct.com/'+allUrlsProductsList['en']" hreflang="en" />
            <link rel="alternate" :href="'https://edmproduct.com/'+allUrlsProductsList['fr']" hreflang="fr" />
            <link rel="alternate" :href="'https://edmproduct.com/'+allUrlsProductsList['pt']" hreflang="pt" />
			<link rel="alternate" :href="'https://edmproduct.com/'+allUrlsProductsList['ca']" hreflang="ca-es" />            
            <link rel="alternate" :href="'https://edmproduct.com/'+allUrlsProductsList['es']" hreflang="x-default" />            

            <meta name="title" :content="titleProductsList+' | Edmproduct'">
            
            <meta name="description" :content="$t(metadescription)">

            <!-- Open Graph / Facebook -->
            <meta property="og:type" content="website">
            <meta property="og:url" content="https://edmproduct.com/">
            <meta property="og:title" :content="titleProductsList+' | Edmproduct'">
            <meta property="og:description" :content="$t(metadescription)">
            <meta property="og:image" content="https://edmproduct.com/img/edm-logo.png">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://edmproduct.com/">
            <meta property="twitter:title" :content="titleProductsList+' | Edmproduct'">
            <meta property="twitter:description" :content="$t(metadescription)">
            <meta property="twitter:image" content="https://edmproduct.com/img/edm-logo.png">
        </Head>
        <div class="section padding"><div>

            <a class="breadcrumb mb-0 px-0" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
            </a>

        </div></div>

        
        <div class="section light"><div>
            <div class="section"><div>

                <div class="banner centered">
                    <div class="header">            
                        <h1 class="title extra-bold" v-if="!loadingData && categoriesMenu.name">
                            {{ titleProductsList }}
                        </h1>
                        <!-- <h2 v-html="$t('bano.subTitle')" class="subTitle"></h2> -->
                    </div>
                    <!-- <details class="text">
                        <summary v-html="$t('bano.summary.title')"></summary>
                    <p v-html="$t('bano.summary.descriptionParagraph1')"></p>
                    <p v-html="$t('bano.summary.descriptionParagraph2')"></p>
                    </details> -->
                </div>

            </div></div>
            

            

            <div class="banner centered" v-if="error">
                <p v-html="$t(error)"></p>
            </div>
            <div v-else>
                <div v-if="!loadingData" class="d-flex flex-wrap justify-content-start categoryContainer mb-5 mt-2">
                    <div v-for="children in categoriesMenu.children" :key="children.code" class="d-flex flex-row categoryWrap">
                        <router-link :to="{ name: 'ProductsListView', params: { slug: children.slug[Tr.currentLocale] ? children.slug[Tr.currentLocale]['name'] : children.slug['es']['name'], type: children.slug[Tr.currentLocale] ? children.slug[Tr.currentLocale]['type'] : children.slug['es']['type'], code: children.slug[Tr.currentLocale] ? children.slug[Tr.currentLocale]['code'] : children.slug['es']['code'] }}" style="">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img :src="apiUrl+children.slug['es']['code']+'.png'" :alt="children.name[Tr.currentLocale] ? children.name[Tr.currentLocale] : children.name['es']">
                                </div>
                                <div class="ms-3" style="word-break: break-word;">
                                    {{ children.name[Tr.currentLocale] ? children.name[Tr.currentLocale] : children.name["es"] }}
                                </div>
                            </div>
                            
                        </router-link>
                    </div>
                </div>
                <div v-else style="display: flex; justify-content: center;">
                    <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                    </div>
                </div>
            </div>
        </div></div>

    </div>
    </template>


<script>
    import { useMenuStore } from '../../stores/MenuStore'
    import { computed, onMounted } from 'vue'

    import GENERIC_VARIABLES from "../../../config";
    import { Head } from '@vueuse/head'
    import Tr from "@/translations"



// Composition API and external JS
export default {
    name: "CategoriesListView",
    props: ['slug', 'code'],
    components: { Head },
    setup(props) {
       
        const store = useMenuStore()
        let payload
        const apiUrl = process.env.VUE_APP_API_URL_IMAGES+'/storage/categorias-miniaturas/'
    

        const loadingData = computed(() => store.loadingData)
        
        const categoriesMenu = computed(() => store.categoriesMenu)
        const error = computed(() => store.error)
        const allUrlsProductsList = computed(() => store.allUrlsProductsList)
        const titleProductsList = computed(() => store.titleProductsList)
        const metadescription = "metalabels.s"+props.code+".metaDescription"

        payload = {
            supercategory: props.code,
        };

        onMounted(async() => {
			store.getCategoriesMenuData(payload)
        })

        /**
        * This is a function to remove the special character | from javascript
        * More information: https://stackoverflow.com/questions/5796718/html-entity-decode
        */
        const changeMetatagHtml = (html) => {
          let txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;

        }


        return { apiUrl, categoriesMenu, error, loadingData, GENERIC_VARIABLES, Tr, allUrlsProductsList, titleProductsList, metadescription, changeMetatagHtml }

    }

}
</script>
