
import i18n from "@/i18n"
// import router from "@/router"

const Trans = {
    get currentLocale() {
      return i18n.global.locale.value
    },

    get supportedLocales() {
      return i18n.global.availableLocales.toString().split(",")
    },

    set currentLocale(newLocale) {
        i18n.global.locale.value = newLocale
    },

    getDefaultLocale() {      
      // return Trans.defaultLocale
      return i18n.global.locale.value
    },

    isLocaleSupported(locale) {
      return this.supportedLocales.includes(locale)
    },


    i18nRoute(to) {
      return {
        ...to,
        params: {
          locale: Trans.currentLocale,
          ...to.params
        }
      }
    },
    
    async switchLanguage(newLocale) {
        Trans.currentLocale = newLocale
        document.querySelector("html").setAttribute("lang", newLocale)
    },

    async routeMiddleware(to, _from, next) {
      const paramLocale = to.params.locale
      
      if(!Trans.isLocaleSupported(paramLocale)) {
        return next({path: '/es' })
      }

      // if(!Trans.isLocaleSupported(paramLocale)) {
      //   return next(Trans.getDefaultLocale())
      // }
      await Trans.switchLanguage(paramLocale)
      // const newRoute = await Trans.routePathTranslate(paramName)
      // return next(newRoute)
      return next()
    },

    // This function expects router instance and params object to change
    async updatePathParams($router, newParams) {

      // Retrieve current params
      const currentParams = $router.currentRoute.params;

      // Create new params object
      const mergedParams = { ...currentParams, newParams };

      // When router is not supplied path or name,
      // it simply tries to update current route with new params or query
      // Almost everything is optional.
      $router.push({ params: mergedParams });
    }

  }
  export default Trans