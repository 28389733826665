import { defineStore } from "pinia"
import Tr from "@/translations"


export const useSeasonalBannersStore = defineStore("SeasonalBannersStore", {

    state: () => ({
        banners: [],
        error: null,
        loadingData: false
    }),
    actions: {
        async fetchData() {
          try {
            this.loadingData = true
            
            const res = await fetch(process.env.VUE_APP_ROOT_API+'seasonal-banners?language='+Tr.currentLocale)
            const data = await res.json()
            
            if(data.error) throw Error(data.message)
            if(data.code != "200") throw Error(data.message)
    
            this.banners = await data.results
            this.error = null
            
            if(!data.error) {
              this.loadingData = false
    
            }
          } catch (err) {
            this.loadingData = false
            this.error = err.message
          }
        }
    }
})

