	<template>
	<div id="content" class="sections"> 

		<Head>
			<!-- Primary Meta Tags -->
			<title>{{ changeMetatagHtml($t("techAssit.metaTitle")) }}</title>
			<meta name="title" :content="changeMetatagHtml($t('techAssit.metaTitle'))">

			<link rel="canonical" href="https://edmproduct.com/es/asistencia-tecnica" />
			<link rel="alternate" href="https://edmproduct.com/es/asistencia-tecnica" hreflang="es" />
			<link rel="alternate" href="https://edmproduct.com/en/technical-assistance" hreflang="en" />
			<link rel="alternate" href="https://edmproduct.com/fr/assistance-technique" hreflang="fr" />
			<link rel="alternate" href="https://edmproduct.com/pt/assistencia-tecnica" hreflang="pt" />
			<link rel="alternate" href="https://edmproduct.com/ca/assistencia-tecnica" hreflang="ca-es" />
			<link rel="alternate" href="https://edmproduct.com/es/asistencia-tecnica" hreflang="x-default" />
			
			<meta name="description" :content="$t('techAssit.metaDescription')">

			<!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://edmproduct.com/">
			<meta property="og:title" :content="changeMetatagHtml($t('techAssit.metaTitle'))">
			<meta property="og:description" :content="$t('techAssit.metaDescription')">
			<meta property="og:image" content="https://edmproduct.com/img/edm-logo.png">

			<!-- Twitter -->
			<meta property="twitter:card" content="summary_large_image">
			<meta property="twitter:url" content="https://edmproduct.com/">
			<meta property="twitter:title" :content="changeMetatagHtml($t('techAssit.metaTitle'))">
			<meta property="twitter:description" :content="$t('techAssit.metaDescription')">
			<meta property="twitter:image" content="https://edmproduct.com/img/edm-logo.png">
		</Head>



		<div class="section paddingHuge photoBackground" id="backgroundTA1"><div></div></div>
		<div class="section padding"><div>
			<a class="breadcrumb mb-0 px-0" @click="$router.back()">
				<div class="icon back"></div>
				<div class="text">{{ $t("nav.back") }}</div>
			</a>
		</div></div>

		<div class="section paddingBig light verticallyCentered">
			<div class="pt-0">
				<div class="banner centered">
					<div class="header">
						<h1 class="title extra-bold display-5 ls-tighter">{{ $t('techAssit.title') }}</h1>
						<h3 v-html="$t('techAssit.subtitle')" class="subTitle medium-bold text-secondary"></h3>
					</div>
					<div v-html="$t('techAssit.text1')" class="description text-grey"></div>
					<div v-html="$t('techAssit.text2')" class="description text-grey"></div>
					<div v-html="$t('techAssit.text3')" class="description text-grey"></div>
					<div v-html="$t('techAssit.text4')" class="description text-grey"></div>
				</div>
			</div>
		</div>
		<div class="section light paddingHuge-t paddingBigger-b">
			<div>
				<div class="banner">
					<div class="header">
						<h2 class="medium-bold ls-tighter text-secondary">{{ $t('spareParts.title') }}</h2>
					</div>
				</div>
			</div>
		</div>
		<div class="section light double verticallyCentered">
			<div>
				<div class="image" id="backgroundTA2">
				
				</div>
				<div class="banner centered">
					<div class="header">						
						<p class="text-grey">{{ $t('spareParts.descriptionParagraph1') }}</p>
						<p class="text-grey">{{ $t('spareParts.descriptionParagraph2') }}</p>
						<p class="text-grey">{{ $t('spareParts.descriptionParagraph3') }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="section light  paddingHuge-t paddingBigger-b">
			<div>
				<div class="banner">
					<div class="header">
						<h2 class="medium-bold ls-tighter text-secondary">{{ $t('spareParts.whereToBuyHome') }}</h2>
					</div>
				</div>
			</div>
		</div>
		<div class="section light" style="padding-left: 0px; padding-right: 0px;"><div style="width: 100%; overflow: hidden; height: 400px; max-width: 100%;">
			<iframe src="https://www.google.com/maps/d/embed?mid=1IpqNiVINJviqDfOslFCBOg7BivpRPrT0&ehbc=2E312F&ll=40.217462377389126%2C-5.2043832406249955&z=4" width="100%" height="800" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" style="border:0; margin-top: -150px;"></iframe>
		</div></div>

	</div>
	</template>

	<script>

	import { Head } from '@vueuse/head'



	export default {
	name: "TechnicalAssistance",
	components: { Head },

	setup() {
		
		/**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
			*/
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}
		return { changeMetatagHtml }
	}



	}
	</script>
	<style>

	
	h1 {
		margin-top: 0;
		margin-bottom: 0.5rem;
		line-height: 1.25;
	}
	@media (min-width: 1200px) {
		.display-5 {
			font-size: 3.5rem;
		}
	}
	#backgroundTA1 {
		background-image: url('@/assets/img/photos/1.webp');
	}
	@media (min-width:992px) {
		#backgroundTA1 {
			height: 400px;
			background-size: 100%;
		}
	}
	#backgroundTA2 {
		background-image: url('@/assets/img/photos/4.webp');
	}
	</style>



