	import { defineStore } from "pinia"
	import Tr from "@/translations"


	export const useProductStore = defineStore("ProductStore", {

		state: () => ({
			productos: [],
			links: [],
			error: null,
			loadingData: false,
			animating: false,
			titleProductsList: null,
			allUrlsProductsList: []
		}),
		getters: {
			getUserById: (state) => {
				return (userId) => state.users.find((user) => user.id === userId)
			},
			getProductsBySupercategoryCode: (state) => {
				return (code) => {
					state.productos.find((producto) => producto.supercategory.code === code)
				}
				// return (supercategoryCode) => state.productos.filter((producto) => {
				//   return (
				//     producto.supercategory.code === supercategoryCode
				//     )
				// }
				// )
			},
		},
		actions: {
			async fetchData(payload) {
				try {
					let supercategoriesParameter = ""
					let categoriesParameter = ""
					let subcategoriesParameter = ""
					let API = process.env.VUE_APP_ROOT_API+"articles?language="+Tr.currentLocale+"&images=false&thumbnails=false&similares=false&relacionados=false&description=false&features=false&video=false" 
					if(payload.page) {
						API = API+"&paginate=true&page="+payload.page
					}
					if(payload.supercategories) {
						payload.supercategories.forEach(supercategory => {
							supercategoriesParameter += '&supercategories[]='+supercategory
						})
						API = API + supercategoriesParameter
					}
					if(payload.categories) {
						payload.categories.forEach(category => {
						categoriesParameter += '&categories[]='+category
						})
						API = API + categoriesParameter
					}
					if(payload.subcategories) {
						payload.subcategories.forEach(category => {
						subcategoriesParameter += '&subcategories[]='+category
						})
						API = API + subcategoriesParameter
					}
					if(payload.brand) {
						API = API+"&brand="+payload.brand
					}
					if(payload.novelties) {
						API = API+"&novelties="+payload.novelties;
					} 
					if(payload.top) {
						API = API+"&featured="+payload.top;
					} 
					this.loadingData = true
					const res = await fetch(API)

					const data = await res.json()
					if(data.error) throw Error(data.message)
					if (data.code != "200") throw Error(data.message)
					this.productos = await data.results.articles
					this.links = await data.results.links
					this.error = null
					this.loadingData = false
				} catch (err) {
					this.loadingData = false
					this.error = err.message
				}
			},
			async searchData(payload) {
				try {
					this.loadingData = true
					
					const res = await fetch(process.env.VUE_APP_ROOT_API+'articles/searcher?term='+payload.query+'&language='+Tr.currentLocale+'&images=false&thumbnails=false&similares=false&relacionados=false&description=false&features=false&video=false&paginate=false')
					const data = await res.json()
					
					if(data.error) throw new Error(data.message)
					if(data.code != "200") throw new Error(data.message)
			
					this.productos = await data.results.articles
					this.links = await data.results.links
					this.error = null
					
					if(!data.error) {
						this.loadingData = false		
					}
				} catch (err) {
					this.loadingData = false
					this.error = err.message
				}
		
			},
			async getDetails(payload) {
				try {
					this.loadingData = true
					let API = process.env.VUE_APP_ROOT_API
					if(payload.supercategory) {
						API = API + 'supercategories/'+payload.supercategory
					}
					if(payload.category) {
						API = API + 'categories/'+payload.category
					}
					if(payload.subcategory) {
						API = API + 'subcategories/'+payload.subcategory
					}
					API = API + '?language='+Tr.currentLocale
					if(!payload.brand) {
						const res = await fetch(API)
						const data = await res.json()
						this.titleProductsList = data.results.name[Tr.currentLocale]
						for (let i = 0; i < Tr.supportedLocales.length; i++) {
							this.allUrlsProductsList[Tr.supportedLocales[i]] = Tr.supportedLocales[i]+"/"+data.results.slug[Tr.supportedLocales[i]].name+"/"+data.results.slug[Tr.supportedLocales[i]].type+data.results.slug[Tr.supportedLocales[i]].code
						}

					} else {
						this.titleProductsList = payload.brand.replaceAll('-', ' ')
					}
				} catch (err) {
					this.loadingData = false
					this.error = err.message
					// throw Error(err.message)
				}
			}
		}
	})
