<template>
    <div id="content" class="sections">
        <Head>
			<!-- Primary Meta Tags -->
			<title>{{ changeMetatagHtml($t("searcher.metaTitle")) }}</title>
			<meta name="title" :content="changeMetatagHtml($t('searcher.metaTitle'))">
			
			<meta name="description" :content="$t('searcher.metaDescription')">

			<!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://edmproduct.com/">
			<meta property="og:title" :content="changeMetatagHtml($t('searcher.metaTitle'))">
			<meta property="og:description" :content="$t('searcher.metaDescription')">
			<meta property="og:image" content="https://edmproduct.com/img/edm-logo.png">

			<!-- Twitter -->
			<meta property="twitter:card" content="summary_large_image">
			<meta property="twitter:url" content="https://edmproduct.com/">
			<meta property="twitter:title" :content="changeMetatagHtml($t('searcher.metaTitle'))">
			<meta property="twitter:description" :content="$t('searcher.metaDescription')">
			<meta property="twitter:image" content="https://edmproduct.com/img/edm-logo.png">
		</Head>
        <div class="section padding"><div>

            <a class="breadcrumb mb-0 px-0" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
            </a>

        </div></div>
        <div class="section light"><div>
            <div class="section paddingHuge"><div>

                <div class="banner centered">
                    <div class="header">            
                        <h1 class="title extra-bold ls-tighter">{{ $t("searcher.title") }}</h1>
                        <h2 v-html="$t('searcher.subTitle')" class="subTitle"></h2>
                        <div class="subTitle" style="margin-top: var(--marginBig)">{{  $t("searcher.searching", {term: term}) }}</div>
                    </div>
                </div>

			</div></div>

            <div class="banner centered" v-if="error">
                <p v-html="$t(error, {term: term})"></p>
            </div>
            <div v-else>
                <div v-if="!loadingData">
                    <ArticlesList :articles="articles" />
                </div>
                <div v-else style="display: flex; justify-content: center;">
                    <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                    </div>
                </div>
                <div class="banner centered">
                    <v-pagination class="mb-4" v-if="Object.keys(links).length !== 0"
                        v-model="page"
                        :pages="links[0].lastPage"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="updateHandler(term)"
                    />
                </div>
            </div>
        </div></div>
    </div>
</template>


<script>
// import searcher from '../../composables/searcher'
import { useProductStore } from '../../stores/ProductStore'
import { computed, ref, onMounted } from 'vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'



// Composition API and external JS
export default {
    props: ['query'],
    name: "SearcherView",
    components: { ArticlesList, Head, VPagination },
    setup(props) {

        const subcategories = []

        const loadingData = computed(() => store.loadingData)
         
        const store = useProductStore()
        const articles = computed(() => store.productos)
        const links = computed(() => store.links)
        const error = computed(() => store.error)
        const page = ref(1)
        const term = String(props.query)


        const payload = {
            query: term,
            page: page.value
        };

        onMounted(async() => {
            store.searchData(payload)
        })
        /**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}


        const updateHandler = (term) => {
            const payload = {
                query: term,
                page: page.value
            };

            store.searchData(payload)
        }

        return { articles, links, error, loadingData, page, term, GENERIC_VARIABLES, subcategories, changeMetatagHtml, updateHandler }

    }

}
</script>
