    <template>
    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ changeMetatagHtml($t("allArticles.metaTitle")) }}</title>

            <link rel="canonical" href="https://edmproduct.com/es/catalogo-de-productos" />
            <link rel="alternate" href="https://edmproduct.com/es/catalogo-de-productos" hreflang="es" />
            <link rel="alternate" href="https://edmproduct.com/en/products-catalogue" hreflang="en" />
            <link rel="alternate" href="https://edmproduct.com/fr/catalogue-de-produits" hreflang="fr" />
            <link rel="alternate" href="https://edmproduct.com/pt/catalogo-de-produtos" hreflang="pt" />
			<link rel="alternate" href="https://edmproduct.com/ca/cataleg-de-productes" hreflang="ca-es" />            
            <link rel="alternate" href="https://edmproduct.com/es/catalogo-de-productos" hreflang="x-default" />

            <meta name="title" :content="changeMetatagHtml($t('allArticles.metaTitle'))">
            
            <meta name="description" :content="$t('allArticles.metaDescription')">

            <!-- Open Graph / Facebook -->
            <meta property="og:type" content="website">
            <meta property="og:url" content="https://edmproduct.com/">
            <meta property="og:title" :content="changeMetatagHtml($t('allArticles.metaTitle'))">
            <meta property="og:description" :content="$t('allArticles.metaDescription')">
            <meta property="og:image" content="https://edmproduct.com/img/edm-logo.png">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://edmproduct.com/">
            <meta property="twitter:title" :content="changeMetatagHtml($t('allArticles.metaTitle'))">
            <meta property="twitter:description" :content="$t('allArticles.metaDescription')">
            <meta property="twitter:image" content="https://edmproduct.com/img/edm-logo.png">
        </Head>

        <div class="section padding"><div>

            <a class="breadcrumb mb-0 px-0" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
            </a>

        </div></div>
        <div class="section light"><div>
            <div class="section paddingHuge"><div>

                <div class="banner centered">
                    <div class="header">            
                        <h1 class="title">{{ $t("allArticles.title") }}</h1>
                        <!-- <h2 v-html="$t('allArticles.subTitle')" class="subTitle"></h2> -->
                    </div>
                    <!-- <details class="text">
                        <summary v-html="$t('allArticles.summary.title')"></summary>
                    <p v-html="$t('allArticles.summary.descriptionParagraph1')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph2')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph3')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph4')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph5')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph6')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph7')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph8')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph9')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph10')"></p>
                    </details> -->
                </div>

            </div></div>
            

            

            <div class="banner centered" v-if="error">
                <p v-html="$t(error)"></p>
            </div>
            <div v-else>
                <div v-if="!loadingData">
                    <ArticlesList :articles="articles" />
                </div>
                <div v-else style="display: flex; justify-content: center;">
                    <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                    </div>
                </div>
                <div class="banner centered">
                    <v-pagination class="mb-4" v-if="Object.keys(links).length !== 0"
                        v-model="page"
                        :pages="links[0].lastPage"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="updateHandler"
                    />
                </div>
            </div>
        </div></div>

    </div>
    </template>


    <script>
    import { useProductStore } from '../../stores/ProductStore'
    import { computed, onMounted, ref } from 'vue'
    import VPagination from "@hennge/vue3-pagination";
    import "@hennge/vue3-pagination/dist/vue3-pagination.css"

    import GENERIC_VARIABLES from "../../../config";
    import ArticlesList from '@/components/ArticlesList.vue'
    import { Head } from '@vueuse/head'


    // Composition API and external JS
    export default {
    name: "AllArticles",
    components: { ArticlesList, Head, VPagination},
    setup() {

        
        const store = useProductStore()

        const loadingData = computed(() => store.loadingData)

        const articles = computed(() => store.productos)
        const links = computed(() => store.links)
        const error = computed(() => store.error)

        const page = ref(1)

        const payload = {
            page: page.value
        };

        onMounted(async() => {
            store.fetchData(payload)
        })

        /**
        * This is a function to remove the special character | from javascript
        * More information: https://stackoverflow.com/questions/5796718/html-entity-decode
        */
        const changeMetatagHtml = (html) => {
            let txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        }



        // const searchedArticles = computed(() => {
        //     if (activeFilter.value === '') {
        //         return articles.value;
        //     }
        //     // return articles.value.filter((article) => article.subcategoryName.es.toLowerCase() === activeFilter.value.toLowerCase());
        //     return articles.value.filter((article) => {

        //         return (
        //             article.subcategoryCode  === activeFilter.value

        //         );
        //     });
        // });

        // const filterData = (type) => {
        //     activeFilter.value = type;
        // }

        const updateHandler = () => {
            const payload = {
                page: page.value
            };
            store.fetchData(payload)
        }

        return { articles, links, error, loadingData, page, GENERIC_VARIABLES, changeMetatagHtml, updateHandler }
    }

    }
    </script>
    <style>
    .half-circle-spinner, .half-circle-spinner * {
        box-sizing: border-box;
    }

    .half-circle-spinner {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        position: relative;
    }

    .half-circle-spinner .circle {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: calc(60px / 10) solid transparent;
    }

    .half-circle-spinner .circle.circle-1 {
        border-top-color: hsl(var(--proColorHue), var(--proColorSaturation), var(--proColorLightness));
        animation: half-circle-spinner-animation 1s infinite;
    }

    .half-circle-spinner .circle.circle-2 {
        border-bottom-color: 	hsl(var(--proColorHue), var(--proColorSaturation), calc(var(--proColorLightness) + 5%));
        animation: half-circle-spinner-animation 1s infinite alternate;
    }

    @keyframes half-circle-spinner-animation {
        0% {
        transform: rotate(0deg);
        }
        100%{
        transform: rotate(360deg);
        }
    }
    </style>
