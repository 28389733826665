// import { ref } from 'vue'
// import { createStore } from 'vuex'
import { defineStore } from "pinia"
import Tr from "@/translations"


export const useMenuStore = defineStore("MenuStore", {

	state: () => ({
		links: [],
		menus: [],
		categoriesMenu: [],
		error: null,
		loadingData: false,
		titleProductsList: null,
		allUrlsProductsList: []
	}),
	getters: {
	},
	actions: {
		async getSuperCategoriesMenuData() {
			try {
			this.loadingData = true
			
			const res = await fetch(process.env.VUE_APP_ROOT_API+'supercategories?language='+Tr.currentLocale+'&categories=true&hasProducts=true')
			const data = await res.json()
			
			if(data.error) throw Error(data.message)
			if(data.code != "200") throw Error(data.message)

			this.menus = await data.results
			this.error = null
			
			if(!data.error) {
				this.loadingData = false

			}
			} catch (err) {
			this.loadingData = false
			this.error = err.message
			// throw Error(err.message)
			}
		},
		async getCategoriesMenuData(payload) {
			try {
				this.loadingData = true
				const res = await fetch(process.env.VUE_APP_ROOT_API+'supercategories/'+payload.supercategory+'?language='+Tr.currentLocale+'&categories=true&hasProducts=true')
				const data = await res.json()
				
				if(data.error) throw Error(data.message)
				if(data.code != "200") throw Error(data.message)

				this.categoriesMenu = await data.results
				this.titleProductsList = data.results.name[Tr.currentLocale]
				this.error = null
				for (let i = 0; i < Tr.supportedLocales.length; i++) {
					this.allUrlsProductsList[Tr.supportedLocales[i]] = Tr.supportedLocales[i]+"/"+data.results.slug[Tr.supportedLocales[i]].name+"/"+data.results.slug[Tr.supportedLocales[i]].type+data.results.slug[Tr.supportedLocales[i]].code
				}
				
				if(!data.error) {
					this.loadingData = false

				}
			} catch (err) {
				this.loadingData = false
				this.error = err.message
				
				// throw Error(err.message)
			}
		},
		async getFeaturedLinksData() {
			try {
				this.loadingData = true
				
				const res = await fetch(process.env.VUE_APP_ROOT_API+'featured-links?language='+Tr.currentLocale)
				const data = await res.json()
				
				if(data.error)  throw new Error(data.message)
				if(data.code != "200")  throw new Error(data.message)
		
				this.links = await data.results
				this.error = null
				
				if(!data.error) {
				this.loadingData = false
		
				}
			} catch (err) {
				this.loadingData = false
				this.error = err.message
				throw new Error(err.message)
			}

		}
	}
})
