<template>
    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ changeMetatagHtml($t("bestSellers.metaTitle")) }}</title>
            
            <link rel="canonical" href="https://edmproduct.com/es/los-mas-vendidos" />
            <link rel="alternate" href="https://edmproduct.com/es/los-mas-vendidos" hreflang="es" />
            <link rel="alternate" href="https://edmproduct.com/en/best-sellers" hreflang="en" />
            <link rel="alternate" href="https://edmproduct.com/fr/top-ventes" hreflang="fr" />
            <link rel="alternate" href="https://edmproduct.com/pt/os-mais-vendidos" hreflang="pt" />
			<link rel="alternate" href="https://edmproduct.com/ca/els-mes-venuts" hreflang="ca-es" />            
            <link rel="alternate" href="https://edmproduct.com/es/los-mas-vendidos" hreflang="x-default" />

            <meta name="title" :content="changeMetatagHtml($t('bestSellers.metaTitle'))">
            
            <meta name="description" :content="$t('bestSellers.metaDescription')">

            <!-- Open Graph / Facebook -->
            <meta property="og:type" content="website">
            <meta property="og:url" content="https://edmproduct.com/">
            <meta property="og:title" :content="changeMetatagHtml($t('bestSellers.metaTitle'))">
            <meta property="og:description" :content="$t('bestSellers.metaDescription')">
            <meta property="og:image" content="https://edmproduct.com/img/edm-logo.png">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://edmproduct.com/">
            <meta property="twitter:title" :content="changeMetatagHtml($t('bestSellers.metaTitle'))">
            <meta property="twitter:description" :content="$t('bestSellers.metaDescription')">
            <meta property="twitter:image" content="https://edmproduct.com/img/edm-logo.png">
		</Head>
        <div class="section padding"><div>

            <a class="breadcrumb mb-0 px-0" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
        </a>

        </div></div>
        <div class="section light"><div>
            <div class="section paddingHuge"><div>

                <div class="banner centered">
                    <div class="header">            
                        <h1 class="title extra-bold ls-tighter">{{ $t("bestSellers.title") }}</h1>
                        <!-- <h2 v-html="$t('bestSellers.subTitle')" class="subTitle ls-tighter"></h2> -->
                    </div>
                    <!-- <details class="text">
                        <summary v-html="$t('bano.summary.title')"></summary>
                    <p v-html="$t('bano.summary.descriptionParagraph1')"></p>
                    <p v-html="$t('bano.summary.descriptionParagraph2')"></p>
                    </details> -->
                </div>

            </div></div>

                <div class="banner centered" v-if="error">
                    <p v-html="$t(error)"></p>
                </div>
                <div v-else>
                <div v-if="!loadingData">
                    <ArticlesList :articles="articles" />
                </div>
                <div v-else style="display: flex; justify-content: center;">
                    <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                    </div>
                </div>
                <div class="banner centered">
                    <v-pagination class="mb-4" v-if="Object.keys(links).length !== 0"
                        v-model="page"
                        :pages="links[0].lastPage"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="updateHandler"
                    />
                </div>
            </div>
            </div>
        </div>
    </div>
</template>


<script>
import { useProductStore } from '../../stores/ProductStore'
import { computed, onMounted, ref } from 'vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css"

import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'


// Composition API and external JS
export default {
    name: "BestSellers",
    components: { ArticlesList, Head, VPagination },
    setup() { 

        const top = "true"
        const novelties = "false"
        let supercategories = []
        let categories = []
        let subcategories = []
        let brand = ""
        let payload
        const store = useProductStore()
        const loadingData = computed(() => store.loadingData)
        const articles = computed(() => store.productos)
        const links = computed(() => store.links)
        const error = computed(() => store.error)
        const page = ref(1)

        payload = {
            page: page.value,
            supercategories: supercategories,
            categories: categories,
            subcategories: subcategories,
            brand: brand,
            top: top,
            novelties: novelties
        };

        onMounted(async() => {
            store.fetchData(payload)
        })

        /**
        * This is a function to remove the special character | from javascript
        * More information: https://stackoverflow.com/questions/5796718/html-entity-decode
            */
        const changeMetatagHtml = (html) => {
            let txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;

        }
        const updateHandler = () => {
            const payload = {
                page: page.value,
                supercategories: supercategories,
                categories: categories,
                subcategories: subcategories,
                brand: brand,
                top: top,
                novelties: novelties
            };
            store.fetchData(payload)
        }

        return { articles, links, error, loadingData, page, GENERIC_VARIABLES, changeMetatagHtml, updateHandler }
    }

}
</script>
