<template>

    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ changeMetatagHtml($t("dataProtection.metaTitle")) }}</title>

            <link rel="canonical" href="https://edmproduct.com/es/proteccion-de-datos" />
            <link rel="alternate" href="https://edmproduct.com/es/proteccion-de-datos" hreflang="es" />
            <link rel="alternate" href="https://edmproduct.com/en/data-protection" hreflang="en" />
            <link rel="alternate" href="https://edmproduct.com/fr/protection-des-donnees" hreflang="fr" />
            <link rel="alternate" href="https://edmproduct.com/pt/protecao-de-dados" hreflang="pt" />
			<link rel="alternate" href="https://edmproduct.com/ca/proteccio-de-dades" hreflang="ca-es" />            
            <link rel="alternate" href="https://edmproduct.com/es/proteccion-de-datos" hreflang="x-default" />

            <meta name="title" :content="changeMetatagHtml($t('dataProtection.metaTitle'))">
            
            <meta name="description" :content="$t('dataProtection.metaDescription')">

            <!-- Open Graph / Facebook -->
            <meta property="og:type" content="website">
            <meta property="og:url" content="https://edmproduct.com/">
            <meta property="og:title" :content="changeMetatagHtml($t('dataProtection.metaTitle'))">
            <meta property="og:description" :content="$t('dataProtection.metaDescription')">
            <meta property="og:image" content="https://edmproduct.com/img/edm-logo.png">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://edmproduct.com/">
            <meta property="twitter:title" :content="changeMetatagHtml($t('dataProtection.metaTitle'))">
            <meta property="twitter:description" :content="$t('dataProtection.metaDescription')">
            <meta property="twitter:image" content="https://edmproduct.com/img/edm-logo.png">
        </Head>
        <div class="section padding"><div>
            <a class="breadcrumb mb-0 px-0" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
            </a>
        </div></div>
        <div class="section light"><div>
            <div class="section paddingHuge"><div>
                <div class="banner centered">
                    <div class="header">                        
                        <h1 class="title extra-bold ls-tighter">{{ $t("dataProtection.title") }}</h1>
                        <p>{{ $t("dataProtection.version") }}</p>
                    </div>
                </div>
            </div></div>
        </div></div>
        <div class="section paddingBig light mt-4 mb-4">
            <div>
                <div class="row banner">
                    <div class="header">
                        <h2 class="h4 ls-tighter mb-4 medium-bold">{{ $t('dataProtection.legalNotice.title') }}</h2>
                        <p class=""><b>{{ $t('dataProtection.legalNotice.subtitle1') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text1')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text2')"></p>
                        <span class="text-grey mb-1">{{ $t('dataProtection.legalNotice.text3')}} <b>elektro3@elektro3.com</b></span>
                        <p class="mt-3"><b>{{ $t('dataProtection.legalNotice.subtitle2') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text4')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.legalNotice.subtitle3') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text5')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.legalNotice.subtitle4') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text6')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.legalNotice.subtitle5') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text7')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text8')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text9')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.legalNotice.subtitle6') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text10')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text11')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text12')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text13')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text14')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.legalNotice.subtitle7') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text15')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.legalNotice.subtitle8') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text16')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text17')"></p>
                        <span class="text-grey">{{ $t('dataProtection.legalNotice.text18') }} <b>edmproduct.security@hotmail.com</b>{{ $t('dataProtection.legalNotice.text19') }}</span>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text20')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.legalNotice.text21')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.legalNotice.subtitle9') }}</b></p>
                        <p class="text-grey">{{ $t('dataProtection.legalNotice.text22') }} <b>elektro3@elektro3.com</b>{{ $t('dataProtection.legalNotice.text23') }}</p>
                        <span class="text-grey">{{ $t('dataProtection.legalNotice.text24') }} <b>elektro3@elektro3.com</b> {{ $t('dataProtection.legalNotice.text25') }}</span>
                        <br /><br/>
                        <h2 class="h4 ls-tighter mb-4 medium-bold">{{ $t('dataProtection.privacyPolicy.title') }}</h2>
                        <p class="paddingBigTop"><b>{{ $t('dataProtection.privacyPolicy.subtitle1') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.privacyPolicy.text1')"></p>
                        <p class="text-grey">{{ $t('dataProtection.privacyPolicy.text3') }} <b>elektro3@elektro3.com</b> {{ $t('dataProtection.privacyPolicy.text4') }}</p>
                        <br />
                        <span class="text-grey">{{ $t('dataProtection.privacyPolicy.text5') }} <b>elektro3@elektro3.com</b> {{ $t('dataProtection.privacyPolicy.text6') }}</span>
                        <p class="mt-3"><b>{{ $t('dataProtection.privacyPolicy.subtitle2') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.privacyPolicy.text7')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.privacyPolicy.subtitle3') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.privacyPolicy.text8')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.privacyPolicy.subtitle4') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.privacyPolicy.text9')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.privacyPolicy.text10')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.privacyPolicy.subtitle5') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.privacyPolicy.text11')"></p>
                        <p class="text-grey">{{ $t('dataProtection.privacyPolicy.text12') }} <b>elektro3@elektro3.com</b></p>
                        <br /><br/>
                        <h2 class="h4 ls-tighter mb-4 medium-bold">{{ $t('dataProtection.cookiePolicy.title') }}</h2>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text1')"></p><br />
                        <a @click="showCookies()" href="javascript:void(0)">{{ $t('dataProtection.cookiePolicy.text2') }}</a>
                        <p class="mt-3"><b>{{ $t('dataProtection.cookiePolicy.subtitle1') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text3')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text4')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text5')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text6')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.cookiePolicy.subtitle2') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text7')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text8')"></p>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text9')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.cookiePolicy.subtitle3') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text10')"></p><br />
                        <div class="col-12" style="overflow-wrap: break-word;"><table class="mt-3 table tableWithSpecialBorders table-responsive text-grey">
                            <tbody>
                                <tr>
                                    <td><strong>Google Chrome</strong></td>
                                    <td><a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</a></td>
                                </tr>
                                <tr>
                                    <td><strong>Internet Explorer</strong></td>
                                    <td><a href="http://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">http://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a></td>
                                </tr>
                                <tr>
                                    <td><strong>Mozilla Firefox</strong></td>
                                    <td><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></td>
                                </tr>
                                <tr>
                                    <td><strong>Apple Safari</strong></td>
                                    <td><a href="http://support.apple.com/kb/ph5042">http://support.apple.com/kb/ph5042</a></td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text11')"></p>
                        <p class="mt-3"><b>{{ $t('dataProtection.cookiePolicy.subtitle4') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.cookiePolicy.text12')"></p>
                        <br /><br/>
                        <h2 class="h4 ls-tighter mb-4 medium-bold">{{ $t('dataProtection.dataProtection.title') }}</h2>
                        <p class="paddingBigTop"><b>{{ $t('dataProtection.dataProtection.subtitle1') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.dataProtection.text1')"></p>
                        <div class="col-12" style="overflow-wrap: break-word;"><table class="mt-3 table tableWithSpecialBorders table-responsive text-grey">
                            <tbody>
                                <tr>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text2') }}</strong></td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text3') }}</strong></td>
                                    <td><strong>ELEKTRO 3, S.C.C.L.</strong><br />F43389675<br />C/ BARENYS, 21 POL. IND. ALBA 43480 VILA-SECA<br />977 792 945<br />elektro3@elektro3.com</td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: none;"><strong>{{ $t('dataProtection.dataProtection.text4') }}</strong></td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text5') }}</strong></td>
                                    <td v-html="$t('dataProtection.dataProtection.text6')"></td>
                                </tr>
                                <tr>
                                    <td style="border: none;">&nbsp;</td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text7') }}</strong></td>
                                    <td>{{ $t('dataProtection.dataProtection.text8') }}</td>
                                </tr>
                                <tr>
                                    <td style="border: none;">&nbsp;</td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text9') }}</strong></td>
                                    <td>{{ $t('dataProtection.dataProtection.text10') }}</td>
                                </tr>
                                <tr>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text11') }}</strong></td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text12') }}</strong></td>
                                    <td>{{ $t('dataProtection.dataProtection.text13') }}</td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: none;"><strong>{{ $t('dataProtection.dataProtection.text14') }}</strong></td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text15') }}</strong></td>
                                    <td>{{ $t('dataProtection.dataProtection.text16') }}</td>
                                </tr>
                                <tr>
                                <td style="border: none;">&nbsp;</td>
                                <td><strong>{{ $t('dataProtection.dataProtection.text17') }}</strong></td>
                                <td>{{ $t('dataProtection.dataProtection.text18') }}</td>
                                </tr>
                                <tr>
                                <td style="border: none;">&nbsp;</td>
                                <td><strong>{{ $t('dataProtection.dataProtection.text19') }}</strong></td>
                                <td>{{ $t('dataProtection.dataProtection.text20') }}</td>
                                </tr>
                                <tr>
                                <td><strong>{{ $t('dataProtection.dataProtection.text21') }}</strong></td>
                                <td><strong>{{ $t('dataProtection.dataProtection.text22') }}</strong></td>
                                <td>{{ $t('dataProtection.dataProtection.text23') }}
                                    <br /><br /><ul><li>{{ $t('dataProtection.dataProtection.text24') }}</li>
                                    <li>{{ $t('dataProtection.dataProtection.text25') }}</li>
                                    <li>{{ $t('dataProtection.dataProtection.text26') }}</li>
                                    <li>{{ $t('dataProtection.dataProtection.text27') }}</li>
                                    <li>{{ $t('dataProtection.dataProtection.text28') }}</li>
                                    </ul>
                                    <br />{{ $t('dataProtection.dataProtection.text29') }} <b>elektro3@elektro3.com</b> {{ $t('dataProtection.dataProtection.text30') }}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>

                        <p class="mt-3"><b>{{ $t('dataProtection.dataProtection.subtitle1') }}</b></p>
                        <p class="text-grey" v-html="$t('dataProtection.dataProtection.text31')"></p>
                        <div class="col-12" style="overflow-wrap: break-word;"><table class="mt-3 table tableWithSpecialBorders table-responsive text-grey">
                            <tbody>
                                <tr>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text32') }}</strong></td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text33') }}</strong></td>
                                    <td><strong>ELEKTRO 3, S.C.C.L.</strong><br />F43389675<br />C/ BARENYS, 21 POL. IND. ALBA 43480 VILA-SECA<br />977 792 945<br />elektro3@elektro3.com</td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: none;"><strong>{{ $t('dataProtection.dataProtection.text34') }}</strong></td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text35') }}</strong></td>
                                    <td v-html="$t('dataProtection.dataProtection.text36')"></td>
                                </tr>
                                <tr>
                                    <td style="border: none;">&nbsp;</td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text37') }}</strong></td>
                                    <td>{{ $t('dataProtection.dataProtection.text38') }}</td>
                                </tr>
                                <tr>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text39') }}</strong></td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text40') }}</strong></td>
                                    <td>{{ $t('dataProtection.dataProtection.text41') }}</td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: none;"><strong>{{ $t('dataProtection.dataProtection.text42') }}</strong></td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text43') }}</strong></td>
                                    <td>{{ $t('dataProtection.dataProtection.text44') }}<br />{{ $t('dataProtection.dataProtection.text45') }}</td>
                                </tr>
                                <tr>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text46') }}</strong></td>
                                    <td><strong>{{ $t('dataProtection.dataProtection.text47') }}</strong></td>
                                    <td>{{ $t('dataProtection.dataProtection.text48') }} <b>elektro3@elektro3.com</b> {{ $t('dataProtection.dataProtection.text49') }}
                                    <br /> {{ $t('dataProtection.dataProtection.text50') }}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>





                        

                        

                    </div>
                </div>
            </div>
        </div>

    </div>


  

</template>


<script>
import GENERIC_VARIABLES from "../../../config";
import { Head } from '@vueuse/head'
import {} from '../../components/cookieConsent/cookieconsent.min.js'



// Composition API and external JS
export default {
    name: "DataProtection",
    components: { Head },

    setup() {

        /**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}

        const showCookies = () => {
            document.querySelector(".ccb__edit").click()
        }

        return { GENERIC_VARIABLES, changeMetatagHtml, showCookies }
    }

}
</script>
<style>
table {
    /* overflow-x: scroll; */
    overflow-wrap: break-word
} 

table a {
    overflow-wrap: anywhere;
}
table.tableWithSpecialBorders {
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 0.6rem;
}
@media (min-width:992px) {
	table.tableWithSpecialBorders {
		font-size: 1rem;
	}
}
table.tableWithSpecialBorders td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 15px;
}

</style>