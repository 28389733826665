import { ref } from "vue";
import Tr from "@/translations"




const getArticle = (slug,code) => {

    const API = process.env.VUE_APP_ROOT_API+"articles/" + code + "?language="+Tr.currentLocale+"&description=true&images=true&thumbnails=true&video=true&features=true&relacionados=true&similares=true&files=true&threesixty=false"
    const item = ref(null)
    const error = ref(null)


    const load = async () => {
        try {
            let response = await fetch(API)
            
            let data = await response.json()

            if(data.error) throw Error("Article not exist")
            if (data.code != "200") throw Error("Article not exist")

            
            
            item.value = await data.results
            
            // if(item.value.url[Tr.currentLocale]['supercategorySlug'] != supercategory) throw Error("Article not exist")
            // if(item.value.url[Tr.currentLocale]['categorySlug'] != category) throw Error("Article not exist")
            // if(item.value.url[Tr.currentLocale]['articleSlug'] != slug) throw Error("Article not exist")


        } catch (err) {
            error.value = err.message
        }

    }
    return { item, error, load } 

}

export default getArticle