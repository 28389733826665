	<template>
	<div id="content" class="sections"> 

		<Head>
			<!-- Primary Meta Tags -->
			<title>{{ changeMetatagHtml($t("aboutUs.metaTitle")) }}</title>
			<meta name="title" :content="changeMetatagHtml($t('aboutUs.metaTitle'))">

            <link rel="canonical" href="https://edmproduct.com/es/quienes-somos" />
            <link rel="alternate" href="https://edmproduct.com/es/quienes-somos" hreflang="es" />
            <link rel="alternate" href="https://edmproduct.com/en/who-we-are" hreflang="en" />
            <link rel="alternate" href="https://edmproduct.com/fr/a-propos-de-nous" hreflang="fr" />
            <link rel="alternate" href="https://edmproduct.com/pt/quem-somos" hreflang="pt" />
			<link rel="alternate" href="https://edmproduct.com/ca/qui-som" hreflang="ca-es" />            
            <link rel="alternate" href="https://edmproduct.com/es/quienes-somos" hreflang="x-default" />
			
			<meta name="description" :content="$t('aboutUs.metaDescription')">

			<!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://edmproduct.com/">
			<meta property="og:title" :content="changeMetatagHtml($t('aboutUs.metaTitle'))">
			<meta property="og:description" :content="$t('aboutUs.metaDescription')">
			<meta property="og:image" content="https://edmproduct.com/img/edm-logo.png">

			<!-- Twitter -->
			<meta property="twitter:card" content="summary_large_image">
			<meta property="twitter:url" content="https://edmproduct.com/">
			<meta property="twitter:title" :content="changeMetatagHtml($t('aboutUs.metaTitle'))">
			<meta property="twitter:description" :content="$t('aboutUs.metaDescription')">
			<meta property="twitter:image" content="https://edmproduct.com/img/edm-logo.png">
		</Head>



		<div class="section paddingHuge photoBackground" id="backgroundAboutUs1"><div></div></div>

		<GoBack />

		<div class="section paddingBig light verticallyCentered">
			<div class="pt-0">
				<div class="banner centered">
					<div class="header">
						<h1 class="title extra-bold display-5 ls-tighter">{{ $t('aboutUs.title') }}</h1>
					</div>
					<div v-html="$t('aboutUs.text1')" class="description text-grey"></div>
					<div v-html="$t('aboutUs.text2')" class="description text-grey"></div>
				</div>
			</div>
		</div>
		<div class="section light paddingBigger-t paddingBig-b">
			<div>
				<div class="banner">
					<div class="header">
						<h2 class="medium-bold ls-tighter text-secondary">{{ $t('specializedIn.title') }}</h2>
					</div>
				</div>
			</div>
		</div>
		<div class="section light"><div>

			<div class="panelButtons">

				<div class="panelButton">
					<div class="texts">
						<p class="text text-grey">{{ $t('specializedIn.text1') }}</p>
					</div>
									
					<div class="photo" id="backgroundAboutUs2">
					</div>
				</div>
				<div class="panelButton">
					<div class="texts">
						<p class="text text-grey">{{ $t('specializedIn.text2') }}</p>
						<p class="text text-grey">{{ $t('specializedIn.text3') }}</p>
					</div>
									
					<div class="photo" id="backgroundAboutUs3"></div>
				</div>

			</div>

		</div></div>

		<div class="section light paddingHuge-t paddingBigger-b">
			<div>
				<div class="banner">
					<div class="header">
						<h2 class="medium-bold ls-tighter text-secondary">{{ $t('whereToBuyHome.title1') }} {{ $t('whereToBuyHome.title2') }}</h2>
					</div>
				</div>
			</div>
		</div>
		<div class="section light" style="padding-left: 0px; padding-right: 0px;"><div style="width: 100%; overflow: hidden; height: 400px; max-width: 100%;">
			<iframe src="https://www.google.com/maps/d/embed?mid=1IpqNiVINJviqDfOslFCBOg7BivpRPrT0&ehbc=2E312F&ll=40.217462377389126%2C-5.2043832406249955&z=4" width="100%" height="800" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" style="border:0; margin-top: -150px;"></iframe>
		</div></div>

	</div>
	</template>

	<script>

	import { Head } from '@vueuse/head'
	import GoBack from '@/components/GoBack.vue'




	export default {
	name: "AboutUs",
	components: { Head, GoBack },

	setup() {
		
		/**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
			*/
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}
		return { changeMetatagHtml }
	}



	}
	</script>
	<style>

	
	h1 {
		margin-top: 0;
		margin-bottom: 0.5rem;
		line-height: 1.25;
	}
	@media (min-width: 1200px) {
		.display-5 {
			font-size: 3.5rem;
		}
	}
	#backgroundAboutUs1 {
		background-image: url('@/assets/img/photos/Bodego_packaging_pae.webp');
	}
	@media (min-width:992px) {
		#backgroundAboutUs1 {
			height: 400px;
			background-size: 100%;
		}
	}
	#backgroundAboutUs2 {
		background-image: url('@/assets/img/photos/07656_43.webp');
	}
	#backgroundAboutUs3 {
		background-image: url('@/assets/img/photos/2023-julio-15.webp');
	}
	
	</style>



