<template>
    <div class="productCards">
        <router-link 
            :to="{ name: 'ArticleDetailsView', params: { slug: item.url[Tr.currentLocale] ? item.url[Tr.currentLocale]['articleSlug'] : item.url['es']['articleSlug'], code: item.code } }" 
            :class="productCardBackground" 
            v-for="(item, index) in articles" 
            :key="item.code"
        >
            <!-- <div v-if="item.novelty" class="ribbon">
					<div class="ribbonText">
                        
						<span><div class="icon new" style="color: var(--colorBlack);"></div> NEW</span>
					</div>
				</div> -->

            <img :src="apiUrl+item.thumbnail" :alt="item.name[Tr.currentLocale] ? item.name[Tr.currentLocale]+' | '+ extraKeyword : item.name['es']+' | '+ extraKeyword" :title="item.name[Tr.currentLocale] ? item.name[Tr.currentLocale]+' | '+ extraKeyword : item.name['es']+' | '+ extraKeyword" loading="lazy" v-if="index === 5" class="mb-1">

            <img :src="apiUrl+item.thumbnail" :alt="item.name[Tr.currentLocale] ? item.name[Tr.currentLocale] : item.name['es']" :title="item.name[Tr.currentLocale] ? item.name[Tr.currentLocale] : item.name['es']" loading="lazy" v-else class="mb-1">
            <div class="labels" style="justify-content: center; gap: var(--marginSmall);">
                <div class="label small" v-if="item.novelty">
                    <div class="icon new"></div>
                    <div class="title text-uppercase">{{ $t("article.labels.novelty") }}</div>
                </div>
                <div class="label small" v-if="item.featured">
                    <div class="icon top-seller"></div>
                    <div class="title text-uppercase">{{ $t("article.labels.top-seller") }}</div>
                </div>
                
            </div>
            <div class="texts">
                <div class="title" v-if="truncateTitle">
                    <span v-if="item.name[Tr.currentLocale]">
                      {{item.name[Tr.currentLocale] ? truncate(item.name[Tr.currentLocale], 35, '...') : truncate(item.name["es"], 35, '...') }}
                    </span>
                </div>
                <div class="title" v-else>
                    {{item.name[Tr.currentLocale] ? item.name[Tr.currentLocale] : item.name["es"]}}
                </div>
                
            </div>
        </router-link>
    </div>    
</template>

<script>
import GENERIC_VARIABLES from "../../config";
import Tr from "@/translations"



export default {
    props: ['articles', 'keyword', 'filtersRef', 'typeOfBackground', 'truncateTitle', 'loadingData'],
    setup(props) {
        const apiUrl = process.env.VUE_APP_API_URL_IMAGES
        const productCardBackground = (props.typeOfBackground ? props.typeOfBackground : 'lightBackground') + " productCard"

        const extraKeyword = props.keyword ? props.keyword : ''

        const truncate = (text, length, suffix) => {
            if (text.length > length) {
              return text.substring(0, length) + suffix
            } else {
              return text
            }
        }

        return { apiUrl, GENERIC_VARIABLES, extraKeyword, productCardBackground, Tr, truncate}
    }
    
}
</script>