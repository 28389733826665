<template>
    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>Error 404</title>
            <meta name="title" content="Error 404">
            <link rel="canonical" href="https://edmproduct.com/404" />
            <link rel="alternate" href="https://edmproduct.com/404" hreflang="es" />
            <link rel="alternate" href="https://edmproduct.com/404" hreflang="en" />
            <link rel="alternate" href="https://edmproduct.com/404" hreflang="fr" />
            <link rel="alternate" href="https://edmproduct.com/404" hreflang="pt" />
			<link rel="alternate" href="https://edmproduct.com/404" hreflang="ca-es" />            
            <link rel="alternate" href="https://edmproduct.com/404" hreflang="x-default" />
            
            <meta name="description" :content="changeMetatagHtml($t('home.metaDescription'))">


        </Head>
        <div class="section padding"><div>

            <a class="breadcrumb mb-0 px-0" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
            </a>

        </div></div>
        <div class="section light"><div>

			<div class="banner centered">
				<div class="header">
                    <h1 class="title">{{ $t("notFound.title") }}</h1>
                    <p v-html="$t('notFound.subTitle')" class="subTitle"></p>
                </div>
            </div>
        </div></div>
        <div class="section light paddingBigger">
            <div>
                <div class="banner centered">
                    <img src="@/assets/img/404.gif" :alt="$t('notFound.elektrocutando')" :title="$t('notFound.elektrocutando')" class="img-404" style="cursor: pointer;">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Head } from '@vueuse/head'

export default {
    components: { Head },
    setup() {

        /**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}

        return { changeMetatagHtml }
    }
    
}
</script>

<style>
.img-404:hover {
	-webkit-animation: tiembla 0.1s infinite;
	animation: tiembla 0.1s infinite;
}
@-webkit-keyframes tiembla {
	0%  { -webkit-transform:rotateZ(-5deg); }
	50% { -webkit-transform:rotateZ( 0deg) scale(.8); }
	100%{ -webkit-transform:rotateZ( 5deg); }
}
@keyframes tiembla {
	0%  { -webkit-transform:rotateZ(-5deg); }
	50% { -webkit-transform:rotateZ( 0deg) scale(.8); }
	100%{ -webkit-transform:rotateZ( 5deg); }
}
</style>



