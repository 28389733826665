<template>
    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ item?.name[Tr.currentLocale] ? item?.name[Tr.currentLocale] : item?.name["es"] }}</title>
            <meta name="title" :content="item?.name[Tr.currentLocale] ? item?.name[Tr.currentLocale] : item?.name['es']">

            <link rel="canonical" :href="'https://edmproduct.com/es/blog/'+'/'+item?.slug+'/'" />
            <link rel="alternate" :href="'https://edmproduct.com/es/blog/'+'/'+item?.slug+'/'" hreflang="es" />
            <link rel="alternate" :href="'https://edmproduct.com/en/blog/'+'/'+item?.slug+'/'" hreflang="en" />
            <link rel="alternate" :href="'https://edmproduct.com/fr/blog/'+'/'+item?.slug+'/'" hreflang="fr" />
            <link rel="alternate" :href="'https://edmproduct.com/pt/blog/'+'/'+item?.slug+'/'" hreflang="pt" />
			<link rel="alternate" :href="'https://edmproduct.com/ca/blog/'+'/'+item?.slug+'/'" hreflang="ca-es" />            
            <link rel="alternate" :href="'https://edmproduct.com/es/blog/'+'/'+item?.slug+'/'" hreflang="x-default" />
            
            <!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://edmproduct.com/">
			<meta property="og:title" :content="item?.name[Tr.currentLocale] ? item?.name[Tr.currentLocale] : item?.name['es']">
			<!-- <meta property="og:description" :content="item?.description[Tr.currentLocale] ? truncate(item?.description[Tr.currentLocale], 300, '.') : truncate(item?.description['es'], 300, '.')"> -->
			<meta property="og:image" :content="item?.name[Tr.currentLocale] ? apiUrl+item?.image : 'https://edmproduct.com/img/edm-logo.png'">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://edmproduct.com/">
            <meta property="twitter:title" :content="changeMetatagHtml($t('blog.metaTitle'))">
            <!-- <meta property="twitter:description" :content="item?.description[Tr.currentLocale] ? truncate(item?.description[Tr.currentLocale], 300, '.') : truncate(item?.description['es'], 300, '.')"> -->
            <meta property="twitter:image" :content="item?.name[Tr.currentLocale] ? apiUrl+item?.image : 'https://edmproduct.com/img/edm-logo.png'">
        </Head>
        <div class="section padding"><div>

            <a class="breadcrumb mb-0 px-0" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
            </a>

        </div></div>

        
        <div class="section light"><div>
            <div class="section"><div>
                <div class="banner centered">
                    <div class="header">    
                        <h1 class="title extra-bold" v-if="!loadingData">
                            {{ item?.name[Tr.currentLocale] ? item?.name[Tr.currentLocale] : item?.name["es"] }}
                        </h1>
                    </div>
                </div>

            </div></div>       

            <div class="banner centered" v-if="error">
                <p v-html="$t(error)"></p>
            </div>
            <div v-else>
                <div class="row mt-4 mb-5" v-if="!loadingData">
                    <div class="col-md-12">
                        <article class="blog-post" v-if="item">
                            <div v-if="item.image" :style="getThumbnailImage(item.image)" class="blogImg mb-4"></div>
                            <!-- <h2 class="blog-post-title mb-2 medium-bold" style="letter-spacing: -2px;">{{ item.name[Tr.currentLocale] ? item.name[Tr.currentLocale] : item.name["es"] }}</h2> -->
                            <p class="blog-post-meta mb-2"><small><i>{{ item.createdAt }}</i></small></p>
                            <div class="blog-post-description" v-html="item.description[Tr.currentLocale] ? item.description[Tr.currentLocale] : item.description['es']"></div>
                            <iframe v-if="item.url_youtube" class="mt-4 mb-4" loading="lazy" title="Ideas para su escaparate de Noviembre 2019" width="100%" height="550" :src="item.url_youtube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </article>
                    </div>
                </div>                
                <div v-else style="display: flex; justify-content: center;">
                    <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                    </div>
                </div>
                
            </div>
        </div></div>

        

    </div>
    </template>


<script>
    import { useBlogNewsStore } from '../../stores/BlogNewsStore'
    import { computed, onMounted } from 'vue'


    import GENERIC_VARIABLES from "../../../config";
    import { Head } from '@vueuse/head'
    import Tr from "@/translations"



// Composition API and external JS
export default {
    name: "BlogView",
    props: ['slug'],
    components: { Head },
    setup(props) {
        const store = useBlogNewsStore()
        const apiUrl = process.env.VUE_APP_API_URL_IMAGES+'/storage/news/'      

        const loadingData = computed(() => store.loadingData)
        const item = computed(() => store.item)
        const error = computed(() => store.error)

        const payload = {
            slug: props.slug
        }

        onMounted(async() => {
			store.getBySlug(payload)
        })


        /**
        * This is a function to remove the special character | from javascript
        * More information: https://stackoverflow.com/questions/5796718/html-entity-decode
        */
        const changeMetatagHtml = (html) => {
          let txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;

        }


        const getThumbnailImage = (thumbail) => {
			return "background-image: url('"+apiUrl+thumbail+"')"
        }

        const truncate = (text, length, suffix) => {
            if (text.length > length) {
              return text.replace('<p>', '').replace('</p>', '').substring(0, length) + suffix
            } else {
              return text.replace('<p>', '').replace('</p>', '')
            }
        }


        return { apiUrl, item, error, loadingData, GENERIC_VARIABLES, Tr, changeMetatagHtml, getThumbnailImage, truncate }

    }

}
</script>